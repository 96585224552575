import { Component, OnInit, Input } from '@angular/core';
import { ThemeSelectorService } from 'src/app/services';
import { CustomerPlan } from 'src/app/shared/models';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-parking-element',
  templateUrl: './parking-element.component.html',
  styleUrls: ['./parking-element.component.scss']
})
export class ParkingElementComponent implements OnInit {

  @Input() parkingHash: {};
  totalBalanceParking: number;
  @Input() accordionToggleSubject;
  @Input() accordionHashSubject;
  accordionHash;
  totalParking: number;
  innerWidth = window.innerWidth;
  bsModalRef: BsModalRef;
  isDarkTheme: boolean;
  debug: boolean;
  lotOne: Array<any>;
  lotC: Array<any>;
  lotC0: Array<any>;
  lotW: Array<any>;
  lotE: Array<any>;

  constructor(private themeSelector: ThemeSelectorService,
              public extraService: ExtraService,
              private modalService: ModalsService) { }

  ngOnInit() {
    this.accordionHashSubject.subscribe(
      accordionHash => {
        this.accordionHash = accordionHash;
      }
    );
    this.init();
  }

  private init(): void {
    this.totalParking = Object.keys(this.parkingHash).length;
    this.lotOne = [];
    this.lotC0 = [];
    this.lotC = [];
    this.lotW = [];
    this.lotE = [];
    this.totalBalanceParking = 0;
    // tslint:disable-next-line: forin
    for (const seat in this.parkingHash) {
      const section = seat.split('S_')[1].split('-')[0];
      if (section === 'Lot C') {
        if (this.parkingHash[seat].price === 0) {
          this.lotC0.push(this.parkingHash[seat]);
        } else {
          this.lotC.push(this.parkingHash[seat]);
        }
      }
      if (section === 'Lot 1') {
        this.lotOne.push(this.parkingHash[seat]);
      }
      if (section === 'Lot W') {
        this.lotW.push(this.parkingHash[seat]);
      }
      if (section === 'Lot E') {
        this.lotE.push(this.parkingHash[seat]);
      }
      this.totalBalanceParking += this.parkingHash[seat].price;
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }


  /**
   * Check if the browser it's mobile
   * @returns true in case of mobile, othewise false
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  addParking(): void {
    // this.modalService.parkingSlotModal();
  }

  /**
   * Checks if a there is an accordion tab displayed
   * @param id plan seats to check
   * @returns the accordion if exist, otherwise false
   */
  isTabDisplayed(id: number) {
    if (this.accordionHash != null) {
      if (this.accordionHash[id] != null) {
        return this.accordionHash[id];
      }
    }
    return false;
  }

  /**
   * Checks if the browser is a mobile or the window size is <= than 1199 pixels
   * if it's mobile or <= than 1199 pixels then it will displays a modal with the plan
   * instead the accordion, otherwise displays the accordion.
   * @param accordionId to expand the plan seats
   */
  toggleAccordion(accordionId): void {
    // Next on the subject observable to update the accordion
    this.accordionToggleSubject.next(accordionId);
  }

}
