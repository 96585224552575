import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { GaService } from 'src/app/digitalvenue/services/ga.service';
import { PricingService } from 'src/app/digitalvenue/services/pricing.service';
import { ThemeSelectorService } from 'src/app/services';
import { Seat } from '../../models';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-general-admission-modal',
  templateUrl: './general-admission-modal.component.html',
  styleUrls: ['./general-admission-modal.component.scss']
})
export class GeneralAdmissionModalComponent implements OnInit {

  title: string;
  message: string;
  gaArray: Array<number>;
  generalAdmissionSlots = 1;
  isDarkTheme: boolean;

  constructor(public bsModalRef: BsModalRef,
              private pricing: PricingService,
              private flowControl: FlowControlService,
              private themeSelector: ThemeSelectorService,
              public configService: ConfigurationService,
              private gaService: GaService) {
    this.title = 'General Admission';
    this.message = `How many seats would you like to add?`;
  }

  ngOnInit() {
    this.setGeneralAdmissionSlots();
  }

  /**
   * Hides the modal and confirms the GA selection
   */
  confirm(): void {
    this.bsModalRef.hide();
    // general admission now selects from existing ids, in provenue general admission seats have an id
    const generalAdmissionIds = Object.keys(this.flowControl.currentGeneralAdmissionAvailability);
    generalAdmissionIds.sort();
    let maxLimit = this.generalAdmissionSlots;
    // we select generaladmission seats from ids available
    for ( let i = 1; i <= maxLimit; i++) {
      // const gaSeat: Seat = {
      //   id: this.flowControl.currentGeneralAdmissionArea + '-GA-' + this.gaService.getNextGaIndex(),
      //   section: this.flowControl.currentGeneralAdmissionArea.split('_')[1].split('-')[0],
      //   // seat_row: 'GA',
      //   // seat: this.gaService.getNextGaIndex(),
      //   price: this.flowControl.areapricing['S_Bleacher']['min']
      // };
      if (generalAdmissionIds[i]) {
        const id = generalAdmissionIds[i];
        if (!this.flowControl.Basket[id]) {
          const split = id.split('_')[1].split('-');
          const section = split[0];
          // tslint:disable-next-line: variable-name
          const seat_row = split[1];
          const seat = split[2];
          const price = parseFloat(this.pricing.seatpricing[id]);
          const gaSeat: Seat = {
            id, section, seat_row, seat, price
          };
          this.gaService.gaHashInsert = [gaSeat];
          this.flowControl.Basket[gaSeat.id] = gaSeat;
        } else {
          maxLimit++;
        }
      }
    }
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  setGeneralAdmissionSlots() {
    const maxAddons = this.flowControl.maxAddons;
    const discardedSeats = this.flowControl.seatsToDiscard.length;
    const basket = Object.keys(this.flowControl.Basket).length;
    const gaSlots = (maxAddons + discardedSeats ) - basket;
    this.gaArray = Array.from(Array(gaSlots),  (e, i) => i + 1);
  }

}
