import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeSelectorService } from '../../../services';

@Component({
  selector: 'app-nationals-terms-and-conditions-modal',
  templateUrl: './nationals-terms-and-conditions-modal.component.html',
  styleUrls: ['./nationals-terms-and-conditions-modal.component.scss']
})
export class NationalsTermsAndConditionsModalComponent implements OnInit {

    title: string;

    constructor(public bsModalRef: BsModalRef, private themeSelector: ThemeSelectorService) {
          this.title = 'Terms and Conditions';
    }

    ngOnInit() {
    }

    getTheme() {
        return this.themeSelector.isDarkTheme;
    }

    closeModal(): void {
        this.bsModalRef.hide();
    }

}
