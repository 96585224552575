import { Component, OnInit, Input, Inject } from '@angular/core';
import { DV_CONFIG } from '../digitalvenue.configuration';
import { FlowControlService } from '../services/flow-control.service';
import { UserService } from 'src/app/services/user.service';
import { Subject } from 'rxjs';
import { CustomerPlan, Seat } from 'src/app/shared/models';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { APIConnectionService } from '../services/api-connection.service';
import { DjangoSessionAuthenticationService, ErrorJsonService } from 'src/app/services';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { DVMService } from '../services/dvm.service';
import { IsolatedSeatsApiService } from '../services/isolated-seats-api.service';
import * as globals from '../globals';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalParkingSellComponent } from '../../shared/modals/modal-parking-sell/modal-parking-sell.component';
import { ParkingPriceLevelModel } from '../../shared/models/parking-price-level.model';
import { GroupConstraintService } from '../services/group-constraint.service'

interface ParkingAvailability {
    GENRL: ParkingPriceLevelModel;
    RESV: ParkingPriceLevelModel;
}

@Component({
  selector: 'app-bottom-interface',
  templateUrl: './bottom-interface.component.html',
  styleUrls: ['./bottom-interface.component.scss']
})
export class BottomInterfaceComponent implements OnInit {

  @Input() isSelectionAllowed;
  @Input() discardedSeats: object;
  @Input() selectedSeats: Seat[] | object[];
  bsModalRef: BsModalRef;
  keptSeats: object[];
  lastSelectionCount = 0;
  currentInternalBalance = 0;
  currentBalance = 0;
  timerObservable = new Subject<string>();
  timer;
  customerPlan: CustomerPlan;
  globals = globals;
  isTwinsFlex = false;
  smpClient;
  totalTickets = 0;

  public relocateView() {
    // twins flex flow new
    if (!this.flowControl.upgradePlan && this.isTwinsFlex && Object.keys(this.discardedSeats).length === 0) {
      this.modals.flexAddonsModal(this.customerPlan.plan);
      return;
    }
    //   // Si es plan 4 es un select 64 tienen que hacer upgrade por obligacion
    // if ((this.customerPlan.plan.id === 4 && !this.flowControl.upgradePlan) && (this.configService.client === 'kcroyals')) {
    //     // Mostrar modal + chapa
    //     const addonTitle = 'Advice';
    //     const addonMessage = `You have a Select 64 plan, select a higher plan to continue the process.`;
    //     const acceptBtnName = 'Close';
    //     this.messageService.info(addonTitle, addonMessage, acceptBtnName);
    //     return;
    // }
    //   // Si es plan 8 es un flex 40 o 9 es un plan 80 tienen que hacer upgrade por obligacion
    //   // tslint:disable-next-line:max-line-length
    // if (((this.customerPlan.plan.id === 8 || this.customerPlan.plan.id === 9) && !this.flowControl.upgradePlan) && (this.configService.client === 'washingtonnationals')) {
    //       // Mostrar modal + chapa
    //       const addonTitle = 'Advice';
    //       const addonMessage = `You have a ${this.customerPlan.plan.name} plan, select a higher plan to continue the process.`;
    //       const acceptBtnName = 'Close';
    //       this.messageService.info(addonTitle, addonMessage, acceptBtnName);
    //       return;
    //   }
    if (Object.keys(this.discardedSeats).length > 0) {
      new Promise((s, r) => {
        const keepHash = {};
        for (const seat of this.customerPlan.customer_plan_seats) {
          for (const seatid of Object.keys(this.discardedSeats)) {
            if (seatid === seat['id']) {
              keepHash[seatid] = false;
            }
          }
          if (keepHash[seat.id] == null) {
            this.flowControl.seatsToKeep.push(seat.id);
          }
        }
        this.flowControl.relocationSeatsCount = Object.keys(this.discardedSeats).length;
        for (const discardedSeat of Object.keys(this.discardedSeats)) {
          this.flowControl.seatsToDiscard.push(discardedSeat);
        }
        this.flowControl.Basket = {};
        s(true);
      }).then(() => {
        if (this.flowControl.upgradePlan) {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan, this.flowControl.upgradePlan], { state: { data: this.discardedSeats } });
        } else {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan], { state: { data: this.discardedSeats } });
        }
      });
    // if miami marlins and open house we bypass the rest of clauses, copy pasted from the next else
    } else if (this.configService.client === 'miamimarlins' && this.flowControl.isOpenHouse(this.flowControl.currentPlan)) {
      const customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
      this.flowControl.justAddonsAllowed = true;
      for (const seat of customerPlan.customer_plan_seats) {
        this.flowControl.seatsToKeep.push(seat.id);
      }
      if (this.flowControl.upgradePlan) {
        this.router.navigate(['/digitalvenue/seatselection',
          this.flowControl.currentPlan, this.flowControl.upgradePlan], { state: { data: [] } });
      } else {
        this.router.navigate(['/digitalvenue/seatselection',
          this.flowControl.currentPlan], { state: { data: [] } });
      }
    } else if (this.flowControl.maxAddons > 0) {
      const addonTitle = 'Continue?';
      const addonMessage = `You have not selected any seats from the previous season to relocate.
                            Do you want to continue to the selection screen and purchase new additional seats?`;
      const acceptBtnName = 'Just add new seats';
      const closeBtnName = 'Close';
      this.messageService.info(addonTitle, addonMessage, acceptBtnName, () => {
        const customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
        this.flowControl.justAddonsAllowed = true;
        for (const seat of customerPlan.customer_plan_seats) {
          this.flowControl.seatsToKeep.push(seat.id);
        }
        if (this.flowControl.upgradePlan) {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan, this.flowControl.upgradePlan], { state: { data: [] } });
        } else {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan], { state: { data: [] } });
        }
      }, closeBtnName);
    } else {
      this.modals.errorModal('Please, select at least one seat to relocate.');
    }
  }

  get isCheckoutAvailable(): boolean {
    if (this.selectedSeats != null && this.discardedSeats != null && this.selectedSeats.length) {
      let checkoutAvailable = true;
      if (this.app_config.rules.limitCheckoutBalance && checkoutAvailable) {
        checkoutAvailable = this.internalBalance >= -5;
      }
      if (this.app_config.rules.limitCheckoutSeats && checkoutAvailable) {
        checkoutAvailable = this.selectedSeats.length >= Object.keys(this.discardedSeats).length;
      }
      return checkoutAvailable;
    } else {
      return false;
    }
  }

  get isContinueAvailable(): boolean {
    return (Object.keys(this.discardedSeats).length > 0 || this.flowControl.maxAddons > 0)
    && !(this.flowControl.isFlex && !this.flowControl.upgradePlan);
  }

  get isPulseEnabled(): boolean {
      // return true;
    return (Object.keys(this.discardedSeats).length > 0 || this.flowControl.upgradePlan)
    && !(this.flowControl.isFlex && !this.flowControl.upgradePlan);
  }

  get getTimer() {
    return this.timer;
  }

  hasSelectionChanged() {
    if (this.lastSelectionCount !== this.selectedSeats.length && !this.flowControl.flexMode) {
      this.lastSelectionCount = this.selectedSeats.length;
      this.updateInternalBalance();
      this.updateBalance();
      return true;
    }else{
      //@ts-ignore
      if(this.selectedSeats.length && this.totalTickets != this.selectedSeats[0].num_tickets){
      //@ts-ignore
        this.totalTickets = this.selectedSeats[0].num_tickets
        this.updateInternalBalance();
        this.updateBalance();
        return true;
      }else if(this.selectedSeats.length === 0){
        this.totalTickets = 0;
        this.updateInternalBalance();
        this.updateBalance();
      }
    }
    return false;
  }

  updateInternalBalance() {
    let balance = 0;
    let countDiscarded = 0;
    // tslint:disable-next-line: forin
    for (const id in this.discardedSeats) {
      if (this.flowControl.upgradePlan && this.isParking(this.discardedSeats[id].section)) {
          continue;
      }
      countDiscarded++;
      balance -= this.discardedSeats[id].price;
    }
    let countSelected = 0;
    // tslint:disable-next-line: forin
    for (const id in this.selectedSeats) {
      countSelected++;
      if (this.selectedSeats[id]['price'].price) {
        this.flowControl.flexMode ?
          //@ts-ignore
           balance += (this.selectedSeats[id]['price'].price * this.selectedSeats[id].num_tickets) :
           balance += this.selectedSeats[id]['price'].price ;
      } else {
        balance += this.selectedSeats[id]['price'];
      }
    }
    this.currentInternalBalance = balance;
  }

  updateBalance() {
    let balance = 0;
    // tslint:disable-next-line: forin
    for (const id in this.selectedSeats) {
      if (this.selectedSeats[id]['price'].price) {
        balance += this.selectedSeats[id]['price'].price;
      } else {
        balance += this.selectedSeats[id]['price'];
      }
    }
    // tslint:disable-next-line: forin
    for (const id in this.keptSeats) {
      if (this.keptSeats[id]['price'].price) {
        balance += this.keptSeats[id]['price'].price;
      } else {
        balance += this.keptSeats[id]['price'];
      }
    }
    this.currentBalance = balance;
  }

  get internalBalance() {
    this.hasSelectionChanged();
    return this.currentInternalBalance;
  }

  get totalBalance() {
    this.hasSelectionChanged();
    return this.currentBalance;
  }

  constructor(@Inject(DV_CONFIG) public dvConfig: DigitalvenueConfiguration,
              @Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private modals: ModalsService,
              private router: Router,
              private bsModalService: BsModalService,
              public flowControl: FlowControlService,
              private userService: UserService,
              private dvmService: DVMService,
              private connectionService: APIConnectionService,
              private configService: ConfigurationService,
              private authService: DjangoSessionAuthenticationService,
              private errorCodeService: ErrorJsonService,
              private messageService: MessagingService,
              private modalService: ModalsService,
              private groupConstraintService :GroupConstraintService,
              protected isolationService: IsolatedSeatsService,
              private isolatedSeatsApiService: IsolatedSeatsApiService) {
  }

  /*
    TIMER LOGIC - TO DO: CREATE A TIMER SERVICE SHARED WITH LANDING
  */
  isRelocationAvailable(date: string) {
    const appointmentTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    return (now >= appointmentTimestamp) ? true : false;
  }

  time2string(delta) {
    // Days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Hours
    const hoursNum = Math.floor(delta / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    delta -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(delta / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    delta -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(delta % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return days + 'd ' + hours + ':' + minutes + ':' + seconds;
  }

  setTimer() {
    const date = this.customerPlan.appointment_date_from;
    const appointmentTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    const diff = Math.abs(appointmentTimestamp - now) / 1000;
    const timeString = this.time2string(diff);
    this.timerObservable.next(timeString);

    // if (this.isRelocationAvailable(this.customerPlan.appointment_date_from) && !this.isSelectionAllowed) {
    //   this.flowControl.justAddonsAllowed = true;
    //   this.router.navigate(['/digitalvenue/seatselection',
    //   this.flowControl.currentPlan], { state: { data: [] } });
    //   clearInterval(this.interval);
    // }
  }
  interval;
  setTimerInterval() {
    this.interval = setInterval(() => {
      // console.log('Enter interval, ', this.isSelectionAllowed, this.interval);
      // if (this.isSelectionAllowed) {
      //   clearInterval(this.interval);
      // } else {
      //   this.setTimer();
      //   console.log('SET TIMER');
      // }
      this.setTimer();
    }, 1000);
  }

  ngOnInit() {
    // console.log(this.app_config.rules);
    this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
    this.smpClient = this.configService.client;
    this.isTwinsFlex = this.smpClient === 'minnesotatwins' && this.customerPlan.plan.tier === 4;
    this.timerObservable.subscribe(
      timer => {
        this.timer = timer;
      }
    );
    this.setTimer();
    this.setTimerInterval();
    this.keptSeats = this.calculateKeptSeats();
    this.updateInternalBalance();
    this.updateBalance();
  }

  calculateKeptSeats() {
    const customerPlanSeats = this.customerPlan.customer_plan_seats;
    const discardedSeatsKeys = Object.keys(this.discardedSeats);
    const keptSeats = [];
    customerPlanSeats.forEach(customerPlanSeat => {
      let isDiscarded = false;
      discardedSeatsKeys.forEach(discardedSeat => {
        if (discardedSeat === customerPlanSeat.id) {
          isDiscarded = true;
        }
      });
      if (!isDiscarded) {
        keptSeats.push(customerPlanSeat);
      }
    });
    return keptSeats;
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked() {
  }

  public goCheckout(): void {
    if (this.selectedSeats != null && this.discardedSeats != null && this.selectedSeats.length) {
      let checkoutAvailable = true;
      if (this.app_config.rules.limitCheckoutBalance && checkoutAvailable) {
        checkoutAvailable = this.internalBalance >= -5;
      }
      if (this.app_config.rules.limitCheckoutSeats && checkoutAvailable) {
        checkoutAvailable = this.selectedSeats.length >= Object.keys(this.discardedSeats).length;
      }

     if (this.smpClient === "clevelandguardians") {
        this.groupConstraintService.resetNodesTag('pending')
        const seatGroupsToPreserve = 4
        const {isGroupBroken,unselectedSeatIds} =
          this.groupConstraintService.checkBrokenGroups(this.selectedSeats as Seat[], seatGroupsToPreserve);

        if (isGroupBroken) {
          checkoutAvailable = false;
          this.groupConstraintService.setNodesTag(unselectedSeatIds, 'pending')
          const {acceptBtnName, addonMessage, addonTitle} = this.getBrokenGroupModalData(unselectedSeatIds)
          this.messageService.info(addonTitle, addonMessage, acceptBtnName);
        }
      }

      if (checkoutAvailable) {
        const addonTitle = 'Continue to checkout?';
        const addonMessage = `By clicking confirm, your new seats will be placed on hold.
        Do you want to confirm your new seat selection?`;
        const acceptBtnName = 'Confirm';
        const closeBtnName = 'Close';
        this.messageService.info(addonTitle, addonMessage, acceptBtnName, () => {
          // creates a hold transaction to the current plan
          this.createHoldTransaction(this.customerPlan);
        }, closeBtnName);
      }
    }

    // checks if the internal balance is positive
    // if (this.internalBalance >= 0) {
      // splits the general admissions to get only the section and override it's position array
      // const basket = Object.keys(this.flowControl.Basket);
      // console.log(basket);
      // for (let i = 0; i < basket.length; i++) {
      //   if (basket[i].split('-')[1] === 'GA') {
      //     basket[i] = basket[i].split('-')[0];
      //   }
      // }
      // concat the current basket and the seats to keep
      // const transactionSeats = basket.concat(this.flowControl.seatsToKeep);
      // checks if there is any isoalted seat
      // if (this.app_config.rules.preventIsolatedSeats) {
      //   this.connectionService.checkIsolatedSeats(this.customerPlan.id, transactionSeats).subscribe(
      //     response => {
      //       const isolatedSeats = response.produce_isolation;
      //       // if lenght it's 0 > OK, no isolated seat
      //       // if lenght it's != > KO, there are isolated seats
      //       if (isolatedSeats.length === 0) {
      //         // start with the transaction
      //         this.modals.extraModal();
      //         // this.createHoldTransaction(this.customerPlan);
      //       } else {
      //         this.isolatedSeatsApiService.setPendingSeats(isolatedSeats);
      //         this.isolatedSeatsApiService.printIsolatedSeats(isolatedSeats);
      //         for (let i = 0; i < isolatedSeats.length; i++) {
      //           isolatedSeats[i] = ' ' + isolatedSeats[i].split('S_')[1];
      //         }
      //         this.modals.errorModal(`The selected seats are leaving isolated seats: ${isolatedSeats}`);
      //       }
      //     }
      //   );
      // }

      // this.modals.checkoutModal();

      // const addonTitle = 'Continue to checkout?';
      // const addonMessage = `By clicking confirm, your new seats will be placed on hold.
      // Do you want to confirm your new seat selection?`;
      // const acceptBtnName = 'Confirm';
      // const closeBtnName = 'Close';
      // this.messageService.info(addonTitle, addonMessage, acceptBtnName, () => {
      //   // creates a hold transaction to the current plan
      //   this.createHoldTransaction(this.customerPlan);
      // }, closeBtnName);

      // this.createHoldTransaction(this.customerPlan);
    // }

  }

    getBrokenGroupModalData(unselectedSeatIds: string[]) {

      const addonTitle = "Oops!";
      const acceptBtnName = "Close";
      const addonMessage = `
        Your seat selection is invalid due to seat group constraints.<br>
        Please select the following seats to continue with the operation:<br><br>
          <ul> ${unselectedSeatIds.map(seat => `<li> - <span class="modal-bold-text"> ${seat.replace('S_', '')} </span></li>`).join('')} </ul>
      `;

      // Since styling with a string inserted with innerHTML is not working correctly,
      // this does style the elements with imperative Javascript
      setTimeout(() => {
        const seatsEl = Array.from(document.querySelectorAll('.modal-bold-text')) as HTMLElement[];
        for (const seatEl of seatsEl) {
          seatEl.style.fontWeight = '900'
        }
      }, 0);

      return {addonTitle, addonMessage, acceptBtnName}
    }

    isParking(section: string): boolean {
        let isparking = false;
        const parkingSectionList = ['PARKING', 'PARKRES', 'PREMIUM', 'STMRES', 'TRIPLECRWN'];
        for (const psection of parkingSectionList) {
            if (section === psection) {
                isparking = true;
            }
        }
        return isparking;
    }

  /**
   * Creates the transaction in status hold ( PH ) and redirects to the checkout page,
   * if error displays an error.
   * @param customerPlan to get the selected seats
   */
  createHoldTransaction(customerPlan: CustomerPlan): void {
    let basket = Object.keys(this.flowControl.Basket);

    if (this.flowControl.flexMode) {

      const priceScaleInfo = this.flowControl.availabilty.getValue()[this.flowControl.flexCart.price_scale];

      const quantity = this.flowControl.flexCart.tickets[basket[0]].num_tickets;
      const priceScaleName = priceScaleInfo.name;
      const priceScaleId = this.flowControl.flexCart.price_scale;
      // the target plan is the upgrade plan in upgrades or addons
      // or the plan id of the customer plan, this.flowControl.currentPlan in reality is the customer plan id
      const currentCustomerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
      const targetPlan = this.flowControl.upgradePlan ? this.flowControl.upgradePlan : currentCustomerPlan.plan.id;
      basket = [`Price_scale_name:${priceScaleName}-Price_Scale_id:${priceScaleId}-target_plan:${targetPlan}-quantity:${quantity}`];
    }
    // for (let i = 0; i < basket.length; i++) {
    //   if (basket[i].split('-')[1] === 'GA') {
    //     basket[i] = basket[i].split('-')[0];
    //   }
    // }
    const transactionSeats = basket.concat(this.flowControl.seatsToKeep);
    this.configService.showLoader(true);
    // if we are adding on current plan we don't want the backend to treat as an upgrade, then if we have
    // addon flex plan don't send this value
    const upgradePlan = this.flowControl.addonFlexPlax ? false : this.flowControl.upgradePlan;
    this.connectionService.createHoldTransaction(this.flowControl.currentPlan, transactionSeats, upgradePlan).subscribe(
      success => {
        this.authService.updateUserData().subscribe(
          userData => {
            const transaction = this.userService.getTransactionByPlanId(this.flowControl.currentPlan);
            // if all ok, redirects to the checkout page with the currentPlan
            const p: number = (this.flowControl.upgradePlan) ? this.flowControl.upgradePlan : this.flowControl.currentPlan;
            // guarrada para desactivar los parkings de kc royals, perdonadme.
            if (this.configService.client === 'kcroyalsss') {
                // venta de parking custom de KC ROYALS
                this.connectionService.parkingAvailability(p).toPromise().then((response: {
                    GENRL: ParkingPriceLevelModel;
                    RESV: ParkingPriceLevelModel;
                }) => {
                    this.configService.showLoader(false);
                    const config: ModalOptions = {
                        animated: true,
                        keyboard: false,
                        ignoreBackdropClick: true,
                        class: 'modal-dialog-centered',
                        initialState: {
                            customerPlan: this.customerPlan,
                            parkingAvailability: response,
                            transaction
                        }
                    };
                    this.bsModalRef = this.bsModalService.show(ModalParkingSellComponent, config);
                    const subscription = this.bsModalService.onHide.subscribe((action) => {
                        subscription.unsubscribe();
                        if (this.bsModalRef.content.reason === 'confirm') {
                            this.configService.showLoader(true);
                            this.authService.updateUserData().subscribe(
                                (udata) => {
                                    this.configService.showLoader(false);
                                    this.router.navigate(['/checkout', this.customerPlan.id]);
                                }
                            );
                        } else if (this.bsModalRef.content.reason === 'restart') {
                            this.modalService.restartModal(this.customerPlan.id, this.interval);
                        }
                    });
                });
            } else {
                this.configService.showLoader(false);
                this.router.navigate(['/checkout', this.customerPlan.id]);
            }

            // const extraPromise = new Promise(
            //   (success, reject) => {
            //     // this.modals.extraModal(success, reject);
            //   }
            // ).then(value => {
            //   this.editTransactionSeats(transaction.id);
            // }).catch(value => {
            //   if (transaction.status === 'PH') {
            //     this.router.navigate(['/checkout', this.customerPlan.id]);
            //   }
            // });
          },
          error => {
            this.configService.showLoader(false);
            const errorMessage = error.error;
            this.modalService.errorModal(errorMessage.message);
            // If error, shows a modal with the error code
            // this.errorCodeService.getErrorByCode(1004).subscribe(
            //   errorText => {
            //     // displays an error modal
            //     this.modals.errorModal(errorText);
            //   }
            // );
          }
        );
      },
      error => {
        this.configService.showLoader(false);
        const errorMessage = error.error;
        if (errorMessage.code === 'TCRRS03') {
          // this.isolationService.checkPendingIsolatedSeats();
          // this.isolatedSeatsApiService.setPendingSeats(errorMessage.seats);
          // this.isolatedSeatsApiService.printIsolatedSeats(errorMessage.seats);
          for (let i = 0; i < errorMessage.seats.length; i++) {
            errorMessage.seats[i] = errorMessage.seats[i].split('S_')[1];
          }
          this.modals.errorModal(`The selected seats are leaving isolated seats: ${errorMessage.seats}`);
        } else {
          this.modalService.errorModal(errorMessage.message);
          // If error, shows a modal with the error code
          // this.errorCodeService.getErrorByCode(1005).subscribe(
          //   errorText => {
          //     // displays an error modal
          //     this.modals.errorModal(errorText);
          //   }
          // );
        }
      }
    );
  }

  /**
   * Edits the transaction in status hold ( PH ) and redirects to the checkout page,
   * if error displays an error.
   */
  editTransactionSeats(transactionId: number): void {
    this.userService.editTransactionSeats(transactionId, this.flowControl.extraBasket, 'add').subscribe(
      response => {
        this.authService.updateUserData().subscribe(
          response => {
            const transaction = this.userService.getTransactionByPlanId(this.flowControl.currentPlan);
            this.router.navigate(['/checkout', this.customerPlan.id]);
          },
          error => {
            // If error, shows a modal with the error code
            this.errorCodeService.getErrorByCode(1004).subscribe(
              errorText => {
                // displays an error modal
                this.modals.errorModal(errorText);
              }
            );
          }
        );
      },
      error => {
      }
    );
  }



  public readonly Object = Object;
}
