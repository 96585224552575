import { Component, Inject, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';
import * as globals from '../../../digitalvenue/globals';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  isDarkTheme: boolean;
  private guideStep = 1;
  stepsArray = Array.from(Array(this.maxSteps).keys(), (e, i) => i + 1);

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              public bsModalRef: BsModalRef,
              private configService: ConfigurationService,
              private themeSelector: ThemeSelectorService,
              private storageService: LocalStorageService) {
  }

  get maxSteps() {
    if (globals.isMobile()) {
      return this.app_config.guide.mobileSteps;
    } else {
      return this.app_config.guide.desktopSteps;
    }
  }

  get imagePath() {
    if (globals.isMobile()) {
      return `../../../assets/img/${this.configService.client}/guide/mobile/${this.guideStep}.png`;
    } else {
      return `../../../assets/img/${this.configService.client}/guide/${this.guideStep}.png`;
    }
  }


  isStepActive(stepId) {
    return stepId === this.guideStep;
  }

  setStep(stepId) {
    this.guideStep = stepId;
  }

  isNextAvailable() {
    return this.guideStep < this.maxSteps;
  }

  isPrevAvailable() {
    return this.guideStep > 1;
  }

  nextStep() {
    if (this.isNextAvailable) {
      this.guideStep++;
    }
  }

  prevStep() {
    if (this.isPrevAvailable) {
      this.guideStep--;
    }
  }

  ngOnInit() {
  }

  /**
   * Hides the modal
   */
  confirm(): void {
    this.bsModalRef.hide();
    this.storageService.setItem('guideReaded', JSON.stringify(true));
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
