import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ThemeSelectorService } from 'src/app/services';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';
import { Seat } from '../../models';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { ModalsService } from '../modals.service';
import { ParkingService } from 'src/app/digitalvenue/services/parking.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-parking-slot-modal',
  templateUrl: './parking-slot-modal.component.html',
  styleUrls: ['./parking-slot-modal.component.scss']
})
export class ParkingSlotModalComponent implements OnInit{

  title: string;
  message: string;
  parkingArray: Array<number>;
  parkingAdmissionSlots = 1;
  isDarkTheme: boolean;
  @Input() success;
  public parkingSlots = this.parkingService.parkingSlots;

  constructor(public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService,
              private extraService: ExtraService,
              private flowControl: FlowControlService,
              private moodalService: ModalsService,
              public parkingService: ParkingService) {
  this.title = 'Parking';
  this.message = `How many parking slots would you like to add?`;
}
  ngOnInit() {
  }


 /**
  * Hides the modal and confirms the lexus selection
  */
 confirm(): void {
  this.bsModalRef.hide();
  for (let i = 1; i <= this.parkingAdmissionSlots; i++) {
    const parkingSlot: Seat = {
      id: 'S_Lot C-GA-' + i,
      section: 'GA',
      price: 3960
    };
    // this.extraService.extraHash.parkingHash[parkingSlot.id] = parkingSlot;

    // this.flowControl.Basket[parkingSlot.id] = parkingSlot;
    }
  this.success('yes');
}

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
    this.success('no');
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
