import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.scss']
})
export class UpgradeModalComponent implements OnInit {

  title: string;
  isDarkTheme: boolean;
  customerPlan;
  _upgradePlan;

  constructor(public bsModalRef: BsModalRef,
              public flowControl: FlowControlService,
              private userService: UserService,
              private themeSelector: ThemeSelectorService) {
    this.title = 'Upgrade my plan';
  }

  ngOnInit() {
    this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
    if (this.flowControl.upgradePlan) {
      this.customerPlan.plan_change_availability.forEach(element => {
        if (element.id === this.flowControl.upgradePlan) {
          this._upgradePlan = element.name;
        }
      });
    } else {
      this._upgradePlan = this.customerPlan.plan.name;
    }
  }

  get upgradePlan() {
    return this._upgradePlan;
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
