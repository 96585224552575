import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-admin-login-banner',
  templateUrl: './admin-login-banner.component.html',
  styleUrls: ['./admin-login-banner.component.scss']
})
export class AdminLoginBannerComponent implements OnInit {

  @Input() adminUsername: string;

  constructor() { }

  ngOnInit() {
  }


}
