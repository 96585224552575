
import { Injectable, Inject } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DVMService } from '../services/dvm.service';
import { DVM_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { Popover3dviewService } from '../services/popover-3dview.service';
import { FlowControlService } from '../services/flow-control.service';

@Injectable()
export class PopoverDestroyHandler extends GeneralHandler {
    constructor(protected dvmService: DVMService,
                protected flow: FlowControlService,
                private popover3dview: Popover3dviewService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('leave', dvmService);
    }

    protected handle(obj) {
        this.dvmService.viewer.hover(null);
        this.flow.hoverSection = null;
        // const tooltip: HTMLElement = document.querySelector('#tooltip');
        // tooltip.removeAttribute('data-show');
        this.popover3dview.setTimer();
    }
}
