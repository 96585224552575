import { Component, Inject, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';

@Component({
  selector: 'app-congratulations-modal',
  templateUrl: './congratulations-modal.component.html',
  styleUrls: ['./congratulations-modal.component.scss']
})
export class CongratulationsModalComponent implements OnInit {

  title: string;
  message: string;
  isDarkTheme: boolean;

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService) {
    this.title = 'Congratulations!';
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }

  ngOnInit() {
    // this.message = `Your transaction has been received successfully and these seats are now reserved for you.
    // You will be contacted by a Sales Representative within 1 Business Days to review terms and prepare the necessary
    // paperwork and payment. If you have any questions, please contact your sales representative.`;
    this.message = this.app_config.text.modals.congratulationsModal;

    const fwTemplate = `<div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>`;
    const modalElement = document.getElementsByClassName('modal')[0];
    modalElement.insertBefore(this.createElementFromHTML(fwTemplate), modalElement.firstChild);
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
