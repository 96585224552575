import { Component, OnInit, Input } from '@angular/core';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';

@Component({
  selector: 'app-parking-seat-element',
  templateUrl: './parking-seat-element.component.html',
  styleUrls: ['./parking-seat-element.component.scss']
})
export class ParkingSeatElementComponent implements OnInit {
  @Input() parkingHash;
  @Input() parking;
  @Input() parkingName;
  totalPrice: number;
  constructor(private themeSelector: ThemeSelectorService) { }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.totalPrice = 0;
    for ( const parking of this.parking ) {
      this.totalPrice += parking.price;
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  formatPrice(price: number) {
    return price.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2});
  }

}
