import { Component, OnInit, Input } from '@angular/core';
import { ThemeSelectorService } from '../../../services/theme-selector.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { UserService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plan-seat-element',
  templateUrl: './plan-seat-element.component.html',
  styleUrls: ['./plan-seat-element.component.scss']
})
export class PlanSeatElementComponent implements OnInit {

  @Input() seat;
  @Input() i;
  @Input() seatId;
  @Input() elementsLength: number;
  @Input() plan: any;

  isFlexMode:  boolean;
  isDarkTheme: boolean;

  constructor(private themeSelector: ThemeSelectorService,
              private modalService: ModalsService,
              protected flowService: FlowControlService,
              private configService: ConfigurationService,
              private userService: UserService,
              private route: ActivatedRoute
              ) { }

  ngOnInit() {
    if(this.plan && this.plan.tier === 4){
      this.isFlexMode = true;
    }
  }

  /**
   * Displays the 360 view modal
   */
  open360Modal() {
    this.modalService.panoViewModal(this.seatId);
    // const initialState = {
    //   animated: true,
    //   seatId: this.seatId // seatId to open the pano view
    // };

    // this.bsModalRef = this.modalService.show(PanoViewModalComponent,  { initialState, class: 'modal-lg modal-dialog-centered' });
  }

  formatPrice(price: number) {
    return price.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2});
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  isParking(): boolean {
      let isparking = false;
      const parkingSectionList = ['PARKING', 'PARKRES', 'PREMIUM', 'STMRES', 'TRIPLECRWN'];
      for (const psection of parkingSectionList) {
          if (this.seat.section === psection) {
              isparking = true;
          }
      }
      return isparking;
  }

}
