import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot,
    RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DjangoSessionAuthenticationService } from '../services/django-session-authentication.service';
import { UserService } from '../services';
import { FlowControlService } from './services/flow-control.service';

@Injectable({
    providedIn: 'root'
})
export class DigitalvenueAuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: DjangoSessionAuthenticationService,
                private router: Router,
                private flowControl: FlowControlService,
                private userService: UserService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.userService.clearUrl();
        if (this.flowControl.justAddonsAllowed) {
            return true;
        }
        // Comentar para Open House
        if (!Object.keys(this.flowControl.discardedSeats).length) {
            this.router.navigate(['/digitalvenue/preview', next.paramMap.get('planId')]);
            return false;
        } else {
            return true;
        }
        // return true;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.authService.canActivate();

    }

}
