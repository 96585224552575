import { Injectable } from "@angular/core";
import { DVMService } from "./dvm.service";

export interface Seat {
  id: string;
  section: string;
  seat_row?: string;
  seat?: string;
  price: number;
  price_type?: string;
  price_code_name?: string;
  num_tickets?: number;
}

interface SeatContext {
  row: string[];
  selectedSeatIndex: number;
}

interface GroupNodesSelectedReturn {
  isAllGroupNodesSelected: boolean;
  unselectedSeatIds: string[];
}

interface GroupNodesSelectedParams {
  selectedSeatIndex: number;
  seatsToLeft: number;
  seatsToRight: number;
  row: string[];
}

interface ValidSeatsParams {
  selectedSeatIndex: number;
  row: string[];
  groupSizeToPreserve: number;
  direction: "left" | "right";
}

interface GroupClosedParams {
  selectedSeatIndex: number;
  seatsToLeft: number;
  seatsToRight: number;
  row: string[];
  groupSizeToPreserve: number;
}

interface SeatGroupBrokenReturn {
  isGroupBroken: boolean;
  unselectedSeatIds: string[];
}

@Injectable({
  providedIn: "root",
})
export class GroupConstraintService {
  constructor(private dvmService: DVMService) {}
  private dvmViewer: DVMService["viewer"];
  public seatCache: Map<string, any> = new Map();

  /**
   * Checks if any seat groups are broken based on selected seats and group sizes.
   *
   * @param selectedSeats - The seats that have been selected.
   * @param groupSizes - The size(s) of the groups to preserve.
   * @param skipAmount - The number of selected seats that allows for the checking to be skipped.
   * @returns The result indicating if a group is broken and which seats are unselected.
   */
  public checkBrokenGroups(
    selectedSeats: Seat[],
    groupSizes: number | number[],
    skipAmount: number = Infinity
  ): SeatGroupBrokenReturn {
    const unselectedIdsSet: Set<string> = new Set();
    let isGroupBroken: boolean = false
    // Check if there are any selected seats and if the number of selected seats allows skipping the check
    const hasSelectedSeats = selectedSeats && selectedSeats.length > 0;
    const selectionAllowsSkip = selectedSeats.length >= skipAmount;

    // If no seats are selected or the selection allows skipping, return early with no broken groups
    if (!hasSelectedSeats || selectionAllowsSkip) {
      return { isGroupBroken, unselectedSeatIds: [] };
    }

    this.dvmViewer = this.dvmService.viewer;

    // Normalize groupSizes to an array for iteration,
    // as the API allows for groupSizes to be a number or an array of numbers
    const groupSizesToPreserve = this.getGroupSizes(groupSizes);

    for (const selectedSeat of selectedSeats) {

      // Get seat data for the current selected seat, including its row and index
      // and skip this seat if no seat data is found in neither the map or the cache.
      const seatData = this.getSeatData(selectedSeat.id);
      if(!seatData || !seatData.row || seatData.selectedSeatIndex == -1) continue

      const {row, selectedSeatIndex} = seatData

      // Iterate through each group size to preserve (this is usually just one)
      for (const groupSizeToPreserve of groupSizesToPreserve) {
        const countParams = {
          row,
          groupSizeToPreserve,
          selectedSeatIndex,
        };

        // Count the valid seats to the right and left of the selected seat
        const seatsToRight = this.countValidSeats({ ...countParams, direction: "right" });
        const seatsToLeft = this.countValidSeats({ ...countParams, direction: "left" });

        const groupClosedParams = {
          row,
          seatsToLeft,
          seatsToRight,
          selectedSeatIndex,
          groupSizeToPreserve,
        };

        // Calculate the total number of seats in the group and check if it matches the preserved group size
        const totalSeatsInGroup = seatsToRight + seatsToLeft + 1;
        const isOfPreservedGroupSize = totalSeatsInGroup === groupSizeToPreserve;

        // Check if the group is closed on both sides
        const isGroupClosed = this.isGroupClosed(groupClosedParams)
        if (!isGroupClosed || !isOfPreservedGroupSize) continue;

        const checkNodesParams = {
          row,
          seatsToLeft,
          seatsToRight,
          selectedSeatIndex,
        };

        // Check if all group seats are selected and get any unselected seat IDs
        const { isAllGroupNodesSelected, unselectedSeatIds } = this.checkAllGroupNodesSelected(checkNodesParams);

        if (!isAllGroupNodesSelected) {
          isGroupBroken = true
          // Add unselected seat IDs to the set
         for (const unselectedSeatId of unselectedSeatIds) {
            unselectedIdsSet.add(unselectedSeatId);
         }
        }
      }
    }
    return { isGroupBroken, unselectedSeatIds: Array.from(unselectedIdsSet) };
  }


  public addSeatsToCache(
    selectedSeats: Seat[],
    groupSizes: number | number[],
    skipAmount: number = Infinity
  ): SeatGroupBrokenReturn {
    let isGroupBroken: boolean = false
    const hasSelectedSeats = selectedSeats && selectedSeats.length > 0;
    const selectionAllowsSkip = selectedSeats.length >= skipAmount;

    if (!hasSelectedSeats || selectionAllowsSkip) {
      return { isGroupBroken, unselectedSeatIds: [] };
    }

    this.dvmViewer = this.dvmService.viewer;

    const groupSizesToPreserve = this.getGroupSizes(groupSizes);

    for (const selectedSeat of selectedSeats) {

      const seatData = this.getSeatData(selectedSeat.id);
      if(!seatData || !seatData.row || seatData.selectedSeatIndex == -1) continue

      const {row, selectedSeatIndex} = seatData

      for (const groupSizeToPreserve of groupSizesToPreserve) {
        const countParams = {
          row,
          groupSizeToPreserve,
          selectedSeatIndex,
        };

        this.countValidSeats({ ...countParams, direction: "right" });
        this.countValidSeats({ ...countParams, direction: "left" });

      }
    }
  }

  /**
   * Sets a tag on a list of seat nodes.
   *
   * @param seatIds - The IDs of the seats to tag.
   * @param tag - The tag to set.
   */
  public setNodesTag(seatIds: string[], tag: string) {
    for (const seatId of seatIds) {
      this.dvmService.viewer.setNodesTag(seatId, tag);
    }
  }

  /**
   * Resets a tag on seat nodes by removing it.
   *
   * @param tag - The tag to remove.
   */
  public resetNodesTag(tag: string) {
    const pendingElements = this.dvmService.viewer.getNodesByTag('seat', tag);
    if (!pendingElements.length) return;

    for (const seat of pendingElements) {
      this.dvmService.viewer.setNodesTag(seat.id, 't1');
    }
  }


  /**
   * Clears the seat cache for further use with different availability.
   */
  public resetSeatCache() {
    this.seatCache.clear()
  }

  /**
   * Retrieves seat data for a given seat ID.
   *
   * @param seatId - The ID of the seat.
   * @returns The context of the seat within the row.
   */
  private getSeatData(seatId: string): SeatContext {
    const seatNode = this.getSeatNode(seatId)
    if(!seatNode) return
    const row = seatNode.row;
    const selectedSeatIndex = row.indexOf(seatNode.id);
    return { row, selectedSeatIndex };
  }

  /**
   * It either retrieves the node from DVM (if the node map is active)
   * or from the seat cache. Updates cache of the seat if the seat is found.
   *
   * @param seatId The ID of the seat to search for.
   * @returns A DVM seat node.
   */
  private getSeatNode(seatId: string): any {
    let seatNode = this.dvmViewer.getNodeById(seatId);

    if (!seatNode) {
      seatNode = this.seatCache.get(seatId);
    }
    if(!seatNode) return
    this.seatCache.set(seatNode.id, seatNode);

    return seatNode
  }

  /**
   * Normalizes the group sizes to an array.
   *
   * @param groupSizes - The group sizes to normalize.
   * @returns The normalized group sizes as an array.
   */
  private getGroupSizes(groupSizes: number | number[]): number[] {
    return Array.isArray(groupSizes) ? groupSizes : [groupSizes];
  }

  /**
   * Counts the valid seats in a specified direction from a selected seat.
   *
   * @param params - The parameters for counting valid seats.
   * @returns The number of valid seats.
   */
  private countValidSeats({
    row,
    direction,
    selectedSeatIndex,
    groupSizeToPreserve,
  }: ValidSeatsParams): number {
    let validSeats = 0;
    let increment: number;
    let startIndex: number;
    let endIndex: number;

    if (direction === "right") {
      increment = 1;
      startIndex = selectedSeatIndex + 1;
      // Ensure endIndex doesn't exceed the row length or the group size limit
      endIndex = Math.min( selectedSeatIndex + groupSizeToPreserve, row.length);
    } else if (direction === "left") {
      increment = -1;
      startIndex = selectedSeatIndex - 1;
      // Ensure endIndex doesn't go below the start of the row and respects group size
      // Here we add one to the total (but not on the direction) due to inclusive start
      // and exclusive end nature of indexing iteration in JS
      endIndex = Math.max( selectedSeatIndex - groupSizeToPreserve + 1, 0);
    }

    for (let i = startIndex; this.isSeatWithinBounds(i, endIndex, direction); i += increment) {
      if (this.isSeatValid(row[i])) {
        validSeats++;
      } else {
        break;
      }
    }

    return validSeats;
  }

  /**
   * Checks if all group nodes within a specific range are selected.
   *
   * @param params - The parameters to check group nodes.
   * @returns The result indicating if all group nodes are selected and which seats are unselected.
   */
  private checkAllGroupNodesSelected({
    row,
    seatsToLeft,
    seatsToRight,
    selectedSeatIndex,
  }: GroupNodesSelectedParams): GroupNodesSelectedReturn {
    const unselectedSeatIds: string[] = [];

    const startIndex = selectedSeatIndex - seatsToLeft;
    const endIndex = selectedSeatIndex + seatsToRight;

    for (let i = startIndex; i <= endIndex; i++) {
      if (!this.isSeatSelected(row[i])) {
        unselectedSeatIds.push(row[i]);
      }
    }

    const isAllGroupNodesSelected = unselectedSeatIds.length === 0;

    return { isAllGroupNodesSelected, unselectedSeatIds };
  }

  /**
   * Checks if a group of seats is closed on both ends.
   *
   * @param params - The parameters for checking if a group is closed.
   * @returns True if the group is closed on both ends, false otherwise.
   */
  private isGroupClosed({
    row,
    seatsToLeft,
    seatsToRight,
    selectedSeatIndex,
  }: GroupClosedParams): boolean {
    const leftBoundaryIndex = selectedSeatIndex - seatsToLeft - 1;
    const rightBoundaryIndex = selectedSeatIndex + seatsToRight + 1;

    const isLeftBoundaryClosed = leftBoundaryIndex < 0 || !this.isSeatValid(row[leftBoundaryIndex]);
    const isRightBoundaryClosed = rightBoundaryIndex >= row.length || !this.isSeatValid(row[rightBoundaryIndex]);

    return isLeftBoundaryClosed && isRightBoundaryClosed

  }

  /**
   * Checks if a seat index is within the bounds of the row.
   *
   * @param currentIndex - The current index in the row.
   * @param endIndex - The ending index for the row iteration.
   * @param direction - The direction of the iteration.
   * @returns True if the seat index is within bounds, false otherwise.
   */
  private isSeatWithinBounds(
    currentIndex: number,
    endIndex: number,
    direction: "left" | "right"
  ): boolean {
    if (direction === "right") {
      return currentIndex < endIndex;
    } else {
      return currentIndex >= endIndex;
    }
  }

  /**
   * Checks if a seat is valid based on its state.
   *
   * @param seatId - The ID of the seat.
   * @returns True if the seat is valid, false otherwise.
   */
  private isSeatValid(seatId: string): boolean {
    const seatNode = this.getSeatNode(seatId)
    return seatNode && (seatNode.state === "selected" || seatNode.state === "available");
  }

  /**
   * Checks if a seat is currently selected.
   *
   * @param seatId - The ID of the seat.
   * @returns True if the seat is selected, false otherwise.
   */
  private isSeatSelected(seatId: string): boolean {
    const seatNode = this.getSeatNode(seatId)
    return seatNode.state === "selected";
  }
}
