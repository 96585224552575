import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services/user.service';
import { ErrorJsonService } from 'src/app/services/error-json.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { DjangoSessionAuthenticationService } from 'src/app/services/django-session-authentication.service';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';
import { Router } from '@angular/router';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';

@Component({
  selector: 'app-restart-modal',
  templateUrl: './restart-modal.component.html',
  styleUrls: ['./restart-modal.component.scss']
})
export class RestartModalComponent implements OnInit {

  title: string;
  message: string;
  @Input() public planId: any;
  @Input() public interval: any;
  isDarkTheme: boolean;

  constructor(public bsModalRef: BsModalRef,
              public router: Router,
              private userService: UserService,
              private flowService: FlowControlService,
              private errorCodeService: ErrorJsonService,
              private modalService: BsModalService,
              private auth: DjangoSessionAuthenticationService,
              private themeSelector: ThemeSelectorService) {
    this.title = 'Restart the progress?';
    this.message = `By clicking on confirm, your seats will be released and available for any other customer.
    Are you sure you want to restart?`;
  }

  ngOnInit() {
  }

  /**
   * Hides the modal and calls cancelTransaction function
   */
  confirm(): void {
    this.bsModalRef.hide();
    this.cancelTransaction();
    clearInterval(this.interval);
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
    // make cancel
  }

  /**
   * Cancels the transaction of the current plan, throws an error modal if cancel transaction fails
   */
  cancelTransaction(): void {
    // gets the transacion by plan id
    const transaction = this.userService.getTransactionByPlanId(this.planId);
    // subscribe to cancelTransaction
    this.userService.cancelTransaction(transaction.id).subscribe(
      data => {
        // subscribe and update the user data
        this.flowService.selectedSection = null;
        this.auth.updateUserData().subscribe(
          userData => {
            // redirects to the landing page
            this.router.navigate(['/home']);
          },
          error => {
            console.error(error);
            this.router.navigate(['/home']);
          }
        );
      },
      error => {
        // subscribe and update the user data
        this.flowService.selectedSection = null;
        this.auth.updateUserData().subscribe(
          userData => {
            // redirects to the landing page
            this.router.navigate(['/home']);
          },
          error => {
            console.error(error);
            this.router.navigate(['/home']);
          }
        );
        // cancelTransaction has gone wrong and shows a modal error
        // this.errorCodeService.getErrorByCode(1002).subscribe(
        //   errorText => {
        //     const toSummaryConfig: ModalOptions = {
        //       animated: true,
        //       keyboard: false,
        //       ignoreBackdropClick: true,
        //       class: 'modal-dialog-centered',
        //       initialState: {
        //         message: errorText // param error text to send to ErrorModalComponent
        //       }
        //     };
        //     this.bsModalRef = this.modalService.show(ErrorModalComponent, toSummaryConfig);
        //   }
        // );
      });

  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
