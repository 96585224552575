import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalvenueComponent } from './digitalvenue.component';
import { PreviewComponent } from './preview/preview.component';
import { SeatSelectionComponent } from './seatselection/seatselection.component';
import { AuthGuard } from '../auth/auth.guard';
import { DigitalvenueAuthGuard } from './digitalvenue-auth.guard';

const routes: Routes = [

  { path: 'digitalvenue', canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: 'preview/:planId', component: PreviewComponent},
      { path: 'preview/:planId/:upgrade', component: PreviewComponent},
      { path: 'seatselection/:planId', canActivate: [DigitalvenueAuthGuard], component: SeatSelectionComponent},
      { path: 'seatselection/:planId/:upgrade', canActivate: [DigitalvenueAuthGuard], component: SeatSelectionComponent}
  ]},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    // PreviewModule,
    // SeatSelectionModule
  ],
  exports: [RouterModule]
})
export class DigitalvenueRoutingModule { }
