import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { PlanElementComponent } from './plan-element/plan-element.component';
import { PlanSeatElementComponent } from './plan-element/plan-seat-element/plan-seat-element.component';
import { SharedModule } from '../shared/shared.module';
import { ParkingElementComponent } from './extra/parking-element/parking-element.component';
import { LexusElementComponent } from './extra/lexus-element/lexus-element.component';
import { ParkingSeatElementComponent } from './extra/parking-element/parking-seat-element/parking-seat-element.component';
import { LexusSeatElementComponent } from './extra/lexus-element/lexus-seat-element/lexus-seat-element.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';



@NgModule({
  declarations: [
    LandingComponent,
    PlanElementComponent,
    PlanSeatElementComponent,
    ParkingElementComponent,
    LexusElementComponent,
    ParkingSeatElementComponent,
    LexusSeatElementComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule
  ]
})
export class LandingModule { }
