import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout/checkout.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import {TooltipModule} from "ngx-bootstrap/tooltip";



@NgModule({
  declarations: [CheckoutComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        TooltipModule
    ]
})
export class CheckoutModule { }
