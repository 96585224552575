import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digitalvenue',
  template: '<router-outlet></router-outlet>',
  // styleUrls: ['./seasontickets.component.css']
})
export class DigitalvenueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
