import { Injectable, Inject } from "@angular/core";
import { config } from "rxjs";
import { APP_CONFIG } from "../configuration/ticket-management-portal-configuration";
import { TicketManagementPortalConfiguration } from "../configuration/ticket-management-portal-configuration.model";
import * as configurationJson from './../configuration/configuration.json';
import * as textJson from './../configuration/text.json';


@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    loaderActive = false;
    public isLoaderActive() {
        return this.loaderActive;
    }
    public showLoader(loaderActive) {
        this.loaderActive = loaderActive;
    }

    _client;
    public get client() {
        return this._client;
    }

    constructor(@Inject(APP_CONFIG) private appConfig: TicketManagementPortalConfiguration) {
    }

    public init() {
        // retrieve json from somewhere or have the json compiled and choose one based on url or any other logic
        // https://regex101.com/
        const regex = /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:demo-)?(?:dev-)?(.*)\.3ddigitalvenue\.com.*/gm;
        const client = regex.exec(location.href)[1];
        this._client = client;
        if (client && typeof client === 'string') {
            const configuration = configurationJson['default'][client];
            const text = textJson['default'][client];
            return new Promise<any>((success, reject) => {
                if (configuration) {
                    for (const [key, value] of Object.entries(configuration)) {
                        this.appConfig[key] = value;
                    }
                }
                if (text) {
                    for (const [key, value] of Object.entries(text)) {
                        this.appConfig[key] = value;
                    }
                }
                success(null);
            });
        }
    }

}
