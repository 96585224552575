import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { LandingComponent } from './landing/landing.component';
import { CheckoutComponent } from './checkout/checkout/checkout.component';
import { SummaryComponent } from './checkout/summary/summary.component';
import { environment } from 'src/environments/environment';
import { CommonComponent } from './common/common.component';
import { LoginAuthGuard } from './login/login.guard';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: CommonComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'home', component: LandingComponent},
      { path: 'checkout/:planId', component: CheckoutComponent },
      { path: 'summary/:planId', component: SummaryComponent }
    ]
  },
  {
    path: 'login',
    canActivate: [LoginAuthGuard],
    component: LoginComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        // enableTracing: environment.routes
      }
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
