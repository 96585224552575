import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { DjangoSessionAuthenticationService } from '../services';
import { catchError } from 'rxjs/operators';
import { MessagingService } from '../shared/modals/messaging.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isShown = false;
    constructor(private router: Router,
                private auth: DjangoSessionAuthenticationService,
                private messageService: MessagingService) {}

    private handleAuthError(error: HttpErrorResponse): Observable<any> {
        // handle the auth error or rethrow
        if (error.status === 401 || error.status === 403) {
            if (this.router.url !== '/login' && !this.isShown) {
                this.isShown = true;
                this.messageService.info(
                    'Oops!',
                    `Your session has expired. Please, log in to continue.`,
                    'Accept',
                    () => {
                        this.isShown = false;
                        this.router.navigateByUrl('/login');
                    }
                );
            }
        }
        return throwError(error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.

        // Commented because it's used on other interceptor
        // const headerName = 'X-CSRFTOKEN';
        // const token = this.auth.getCsrfToken() as string;
        // if (token !== null && !request.headers.has(headerName)) {
        //     request = request.clone({ headers: request.headers.set(headerName, token) });
        // }
        return next.handle(request).pipe(catchError( x => this.handleAuthError(x) ));
    }
}
