import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeSelectorService } from '../../../services';
import { APIConnectionService } from '../../../digitalvenue/services/api-connection.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { ModalsService } from '../modals.service';
import { TDCTransaction } from '../../models/tdc_transaction.model';

declare var braintree: any;

@Component({
  selector: 'app-add-digital-wallet-modal',
  templateUrl: './add-digital-wallet-modal.component.html',
  styleUrls: ['./add-digital-wallet-modal.component.scss']
})
export class AddDigitalWalletModalComponent implements OnInit {

  title: string;
  paymentNonce: string;
  @Input() tdcTransaction: TDCTransaction;

  constructor(public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService,
              public configService: ConfigurationService,
              private modalService: ModalsService,
              private apiConnectionService: APIConnectionService) {
    this.title = 'Add New Credit Card to Digital Wallet';
  }

  ngOnInit() {
    this.configService.showLoader(true);
    this.apiConnectionService.braintreeTokenAddCreditCard().subscribe(
      data => {
        this.configService.showLoader(false);
        this.startBraintree(data.token);
      },
      error => {
        const errorMessage = error.error;
        const genericMessage = 'The system is unable to complete your request. Please try again later';
        const finalMessage = errorMessage.message ? errorMessage.message : genericMessage;
        this.configService.showLoader(false);
        this.modalService.errorModal(genericMessage);
        this.closeModal();
      });
  }

  startBraintree(braintreeToken) {
    const button = document.querySelector('#braintree-button-add-credit-card');
    braintree.dropin.create({
      authorization: braintreeToken,
      selector: '#dropin-container-add-credit-card',
      card: {
        cardholderName: {
          required: true
          // to make cardholder name required
          // required: true
        }
      }
      // tslint:disable-next-line:only-arrow-functions
    }, (err, instance) => {
      // tslint:disable-next-line:only-arrow-functions
      button.addEventListener('click', () => {
        // tslint:disable-next-line:only-arrow-functions
        instance.requestPaymentMethod((err, payload) => {
          this.paymentNonce = payload.nonce;
        });
      });
    });

  }

  addCreditCardToDigitalWallet() {
    this.configService.showLoader(true);
    this.apiConnectionService.digitalWalletAddCreditCard(this.paymentNonce).subscribe(
      data => {
        this.configService.showLoader(false);
        // quick and dirty update of digital wallet items shown
        this.tdcTransaction.digital_wallets = data;
        this.closeModal();
      },
      error => {
        // test and add error modal
        const errorMessage = error.error;
        const genericMessage = 'The system is unable to complete your request. Please try again later';
        const finalMessage = errorMessage.message ? errorMessage.message : genericMessage;
        this.configService.showLoader(false);
        this.modalService.errorModal(genericMessage);
        this.closeModal();
      });
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

}
