import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import 'rxjs/add/operator/let';
import { User } from './../shared/models/user.model';
import { AuthorizationData } from '../shared/models/authorization-data';


@Injectable()
export class DjangoSessionAuthenticationService /*implements AuthorizationService*/ {

  constructor(private http: HttpClient) {
    this.userLogged$ = new BehaviorSubject<User | undefined>(undefined);
  }

  private currentUser: any;
  private authorizationUrl = '/auth';
  private csrftoken = null;
  private userLogged$: BehaviorSubject<User | false>;

  private tapSetUser = tap((result: User) => {
    const retValue = this.setUser(result);
    this.currentUser = retValue;
    this.userLogged$.next(retValue);
  });

  private setUser(result: User) {
    let retValue: User | false = result;
    if (result.csrf) {
      this.csrftoken = result.csrf;
    }
    // think return in backend id = false and do !user.id comparisons in components instead of having to type DjangoBaseUser | false in all
    if (result.id === -1) {
      retValue = false;
    }
    return retValue;
  }

  private _getUserLogged(): Observable<User> {
    return this.http.get(`${this.authorizationUrl}/logged/`).pipe(
      this.tapSetUser,
    ) as Observable<User>;
  }

  updateUserData(): Observable<User> {
    return this._getUserLogged();
  }

  login(credentials: AuthorizationData): Observable<User> {
    const data = {
      username: credentials.username,
      password: credentials.password
    };
    return this.http.post(`${this.authorizationUrl}/login/`, data).pipe(
      this.tapSetUser,
    ) as Observable<User>;
  }

  passwordReset(email: string) {
    const data = {email};
    return this.http.post(`${this.authorizationUrl}/password_reset/`, data);
  }

  deleteSelfUserData() {
    return this.http.post(`${this.authorizationUrl}/delete_me/`, {}).pipe(
      tap(data => this.logout())
    );
  }

  logout() {
    const request = this.http.get(`${this.authorizationUrl}/logout/`);
    request.subscribe(() => {
      this.userLogged$.next(undefined);
      this.currentUser = undefined;
    });
    return request;
  }

  getUserLogged(): Promise<User | false> {
    const promise = new Promise<User | false>((resolve, reject) => {
        if (this.currentUser === undefined) {
          this._getUserLogged().toPromise().then(data => {
            resolve(this.setUser(data));
          });
        } else {
          resolve(this.currentUser);
        }
      }
    );
    return promise;
  }

  getUserLogged$(): Observable<User | false> {
    return this.userLogged$.asObservable();
  }

  canActivate(): boolean {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  getCsrfToken() {
    return this.csrftoken;
  }
}
