import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';


@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  title: string;
  message: string;
  isDarkTheme: boolean;

  constructor(public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService) {
    this.title = 'Oops!';
  }

  ngOnInit() {
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
