import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DjangoSessionAuthenticationService } from '../services/django-session-authentication.service';
import { UserService } from '../services';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: DjangoSessionAuthenticationService,
              @Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private router: Router,
              private userService: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.getUserLogged().then((user) => {
            if (user) {
              if (this.app_config.endDate && new Date(this.app_config.endDate)) {
                if (new Date() > new Date(this.app_config.endDate)) {
                  return true;
                }
              }
              this.router.navigate(['/home']);
              return false;
            } else {
              return true;
            }
            // @ts-ignore
          }, Error((e) => {
            return false;
          }));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.authService.canActivate();

  }

}
