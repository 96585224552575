import { Component, OnInit, Input, HostListener, Inject, ChangeDetectorRef } from '@angular/core';
import { CustomerPlan, Seat } from 'src/app/shared/models';
import { Subject } from 'rxjs';
import {
  ThemeSelectorService,
  DjangoSessionAuthenticationService,
  ErrorJsonService,
  UserService
} from 'src/app/services';
import { Router } from '@angular/router';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-plan-element',
  templateUrl: './plan-element.component.html',
  styleUrls: ['./plan-element.component.scss']
})
export class PlanElementComponent implements OnInit {

  @Input() customerPlan: CustomerPlan;
  @Input() i;
  @Input() accordionToggleSubject;
  @Input() accordionHashSubject;
  private parkingSectionList = ['PARKING', 'PARKRES', 'PREMIUM', 'STMRES', 'TRIPLECRWN'];
  accordionHash;
  timerObservable = new Subject<string>();
  timer;
  innerWidth = window.innerWidth;
  isDarkTheme: boolean;
  debug: boolean;
  bsModalRef: BsModalRef;
  _customerPlan: CustomerPlan;

  isSelectSeats = false;

  get customerPlanSeatsLength() {
    return this._customerPlan.customer_plan_seats.length;
  }

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private cdRef: ChangeDetectorRef,
              public configurationService: ConfigurationService,
              private router: Router,
              private flowControl: FlowControlService,
              private themeSelector: ThemeSelectorService,
              private modalService: ModalsService,
              // implemented for testing purposes
              private userService: UserService,
              private auth: DjangoSessionAuthenticationService,
              private errorCodeService: ErrorJsonService) {
    this.debug = false;
  }
  ngOnInit() {
    this._customerPlan = JSON.parse(JSON.stringify(this.customerPlan));
    this._customerPlan.customer_plan_seats = this.sortSeats(this._customerPlan.customer_plan_seats);
    this.deleteParking(this._customerPlan);
    // Subscribes to the subject accordionHashSubject to get the accordionHash
    this.accordionHashSubject.subscribe(
      accordionHash => {
        this.accordionHash = accordionHash;
      }
    );

    // Open first plan first time landing page is open. Accordion Hash is initialized. Only once.
    if (!this.isMobile() && !this.accordionHash && this.i === 0) {
      this.accordionToggleSubject.next(0);
      this.cdRef.detectChanges();
    }

    // Subscribes to the time observable to get the timer
    this.timerObservable.subscribe(
      timer => {
        this.timer = timer;
      }
    );
    this.setTimer();
    this.setTimerInterval();
    // Subscribes to the observable onHide to check if plan seat i'ts modal or not
    this.modalService.modalService.onHide.subscribe((reason: string) => {
      this.bsModalRef = null;
    });
    // this.modalService.onHide.subscribe((reason: string) => {
    //   this.bsModalRef = null;
    // });
    this.isSelectSeats = (this.customerPlanSeatsLength === 0 || this.app_config.flow === 'openhouse');
  }

  /**
   * Event that detects the window size
   * @param event window resize
   */
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // gets the window width
    this.innerWidth = window.innerWidth;
    // check if there is a accordion on
    if (this.isTabDisplayed(this.i)) {
      // if window width is <= than 1199 pixels
      if (this.innerWidth <= 1199) {
        // if modal is null ( not displayed )
        if (this.bsModalRef == null) {
          // displays the PlanSeatElementModalComponent
          this.bsModalRef = this.modalService.planSeatElementModal(this._customerPlan, this.accordionToggleSubject);
          // this.bsModalRef = this.modalService.show(PlanSeatElementModalComponent, { class: 'modal-dialog-centered', initialState });
        }
      } else if (this.bsModalRef != null) {
        // if modal exist then it hides
        this.bsModalRef.hide();
      }
    }
  }

/**
 * Gets the timer
 */
  get getTimer() {
    return this.timer;
  }

  /**
   * Loops the customer plan seats and sums the price of all seats
   * @returns count, the total price of plan seats
   */
  get totalPrice(): number {
    let count = 0;
    for (const seat of this._customerPlan.customer_plan_seats) {
      count += seat.price;
    }
    return Number.parseFloat(count.toFixed(2));
  }

/**
 * @returns the customer plan seats array length
 */
  get totalSeats(): number {
      let totalSeats = 0;
    // return this._customerPlan.customer_plan_seats.length;
      for (const item of this._customerPlan.customer_plan_seats) {
        if (this.parkingSectionList.indexOf(item.section) === -1) {
            totalSeats += 1;
        }
      }
      return totalSeats;
  }

/**
 * Redirects to the preview page with param customerPlan.id
 */
  goToRelocation(skip?) {
    if (skip) {
      this.flowControl.justAddonsAllowed = true;
      this.router.navigate(['/digitalvenue/seatselection',
      this._customerPlan.id], { state: { data: [] } });
    } else {
      this.router.navigate(['/digitalvenue/preview', this._customerPlan.id]);
    }
  }

  /**
   * Checks if the browser is a mobile or the window size is <= than 1199 pixels
   * if it's mobile or <= than 1199 pixels then it will displays a modal with the plan
   * instead the accordion, otherwise displays the accordion.
   * @param accordionId to expand the plan seats
   */
  toggleAccordion(accordionId): void {
    const isMobile = this.isMobile();
    this.innerWidth = window.innerWidth;
    // Checks if the browser is a mobile or the window size is <= than 1199 pixels
    if (isMobile || this.innerWidth <= 1199) {
      // Object to configure the modal with params customerPlan and accordionToggleSubject
      this.modalService.planSeatElementModal(this._customerPlan, this.accordionToggleSubject);
    }
    // Next on the subject observable to update the accordion
    this.accordionToggleSubject.next(accordionId);
  }

/**
 * Checks if a there is an accordion tab displayed
 * @param id plan seats to check
 * @returns the accordion if exist, otherwise false
 */
  isTabDisplayed(id: number) {
    if (this.accordionHash != null) {
      if (this.accordionHash[id] != null) {
        return this.accordionHash[id];
      }
    }
    return false;
  }

/**
 * Compare the date current date and the appointmentTimestamp
 * @param date to compare
 * @returns true in case of the current date it's >= than appointmentTimestamp, otherwise false
 */
  isRelocationAvailable(date: string): boolean {
    // US Central Timezone (-6)
    // NOW USING UTC
    // const timezone = -6;
    // const offset = timezone * 60 * 60 * 1000;
    // appointmentTimestamp -= offset;

    // Parses the time into date object and get's the time
    const appointmentTimestamp = new Date(date).getTime();
    // Gets the current date
    const now = new Date().getTime();
    // If current date it's >= than appointmentTimestamp return true, otherwise false
    return (now >= appointmentTimestamp) ? true : false;

  }

/**
 * Parses the timestamp into days, hours, minutes and seconds
 * @param timeStamp to parse the date into string
 * @returns the formated date
 */
  time2string(timeStamp) {
    // Days
    const days = Math.floor(timeStamp / 86400);
    timeStamp -= days * 86400;
    // Hours
    const hoursNum = Math.floor(timeStamp / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    timeStamp -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(timeStamp / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    timeStamp -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(timeStamp % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return days + 'd ' + hours + ':' + minutes + ':' + seconds;
  }

  /**
   * Sets the timer and updates the observable
   */
  setTimer(): void {
    // Gets the appointment date
    const date = this._customerPlan.appointment_date_from;
    // Gets the timestamp of appointment date
    const appointmentTimestamp = new Date(date).getTime();
    // Gets the current date
    const now = new Date().getTime();
    // Gets the difference between current date and appointment date
    const diff = Math.abs(appointmentTimestamp - now) / 1000;
    // Parses the difference result
    const timeString = this.time2string(diff);
    // Updates the subject with the new value
    this.timerObservable.next(timeString);
  }

/**
 * Sets the timer with an interval of 1 seg (to do a chronometer)
 */
  setTimerInterval(): void {
    setInterval(() => {
      this.setTimer();
    }, 1000);
  }

/**
 * Check if the browser it's mobile
 * @returns true in case of mobile, othewise false
 */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Redirects to the summary page with param customerPlanId
   * @param customerPlanId to send to summary page
   */
  goToSummary(customerPlanId: number): void {
    this.router.navigate(['/summary', this._customerPlan.id]);
  }

  /**
   * Redirects to the checkout page with param customerPlanId
   * @param customerPlanId to send to checkout page
   */
  goToCheckout(customerPlanId: number): void {
    this.router.navigate(['/checkout', this._customerPlan.id]);
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  /**
   * Function for testing purposes
   * @param transactionId to cancel the transaction
   */
  cancelTransaction(transaction): void {
    if (!transaction.master) {
      transaction = transaction.related;
    }
    this.userService.cancelTransaction(transaction.id).subscribe(
      data => {
        this.auth.updateUserData().subscribe(
          userData => {
            this.router.navigate(['/home']);
          },
          error => {
            console.error(error);
          }
        );
      },
      error => {
        console.error(error);
        // cancelTransaction has gone wrong and shows a modal error
        this.errorCodeService.getErrorByCode(1002).subscribe(
          errorText => {
            this.modalService.errorModal(errorText);
            // const toSummaryConfig: ModalOptions = {
            //   animated: true,
            //   keyboard: false,
            //   ignoreBackdropClick: true,
            //   class: 'modal-dialog-centered',
            //   initialState: {
            //     message: errorText // param error text to send to ErrorModalComponent
            //   }
            // };
            // this.bsModalRef = this.modalService.show(ErrorModalComponent, toSummaryConfig);
          }
        );
      }
    );
  }

  private deleteParking(customerPlan: CustomerPlan) {
    const newCustomerPlanSeats = [];
    for (const seat of customerPlan.customer_plan_seats) {
      if (!(seat.section === 'Lot E' ||
      seat.section === 'Lot W' ||
      seat.section === 'Lot 1' ||
      seat.section === 'Lot C' ||
      seat.section === 'Lexus Club')) {
        newCustomerPlanSeats.push(seat);
      }
    }
    customerPlan.customer_plan_seats = newCustomerPlanSeats;
  }

  /**
   * Given an unordered list of chairs, we sort it by section, row and chair number.
   * @param list - lista del tipo {Seat} desordenadas
   * @returns lista ordenada
   */
  private sortSeats(list: Array<Seat>): Array<Seat> {
    return list.sort(function (a, b) {
      // return a.section.localeCompare(b.section) || b.seat_row.localeCompare(a.seat_row) || a.seat.localeCompare(b.seat);
      if (a.section === b.section) {
        if (a.seat_row === b.seat_row) {
          return a.seat.localeCompare(b.seat)
        }
        return a.seat_row.localeCompare(b.seat_row)
      }
      return a.section.localeCompare(b.section)
    });
  }

}
