import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalvenueRoutingModule } from './digitalvenue.routing.module';
import { DigitalvenueComponent } from './digitalvenue.component';
import { TopbarComponent } from './topbar/topbar.component';
import { TicketComponent } from './ticket/ticket.component';
import { SubInterfaceComponent } from './sub-interface/sub-interface.component';
import { SeatSelectionModule } from './seatselection/seatselection.module';
import { SearchComponent } from './search/search.component';
import { MainInterfaceComponent } from './main-interface/main-interface.component';
import { RouterModule } from '@angular/router';
import { DVMConfig, DVM_CONFIG, DV_CONFIG, DigitalVenueConfig } from './digitalvenue.configuration';
import { DVMService } from './services/dvm.service';
import { PreviewModule } from './preview/preview.module';



@NgModule({
  declarations: [
    DigitalvenueComponent,
  ],
  imports: [
    DigitalvenueRoutingModule,
    PreviewModule,
    SeatSelectionModule,
  ],
  exports: [
    DigitalvenueComponent
  ],
  providers: [
    {provide: DV_CONFIG, useValue: DigitalVenueConfig},
    {provide: DVM_CONFIG, useValue: DVMConfig},
    DVMService
  ]
})
export class DigitalvenueModule { }
