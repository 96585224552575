import { Injectable } from '@angular/core';
declare var $: any;
import { FlowControlService } from './flow-control.service';
import { ErrorJsonService } from 'src/app/services/error-json.service';
import { DVMService } from './dvm.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { PricingService } from './pricing.service';

@Injectable({
  providedIn: 'root'
})

/* ISOLATED SEAT HANDLING */

export class IsolatedSeatsService {

  readonly singleSeatsBuffer = [];
  singleSeatsAction = '';

  constructor(private flowControl: FlowControlService,
              private pricing: PricingService,
              private dvmService: DVMService,
              private modals: ModalsService,
              private errorCode: ErrorJsonService) {
                this.dvmService.viewerSubject.subscribe(() => {
                  this.resetBuffer();
                });
              }

  public checkPendingIsolatedSeats() {
    const pendingElements = this.dvmService.viewer.getNodesByTag('seat', 'pending');
    return this.checkIsolatedSeats(pendingElements);
  }

  public checkIsolatedSeats(seats, seatObject = null, action = null) {
    const isolatedSeats = [];
    for (const seat of seats) {
      if (seat.row) {
        const row = seat.row;
        const index = row.indexOf(seat.id);
        const rightId1 = row[index + 1];
        const rightId2 = row[index + 2];
        const leftId1 = row[index - 1];
        const leftId2 = row[index - 2];

        if (!this.check1(rightId1)) {
          if (!this.check2(rightId2)) {
            isolatedSeats.push(rightId1);
          }
        }

        if (!this.check1(leftId1)) {
          if (!this.check2(leftId2)) {
            isolatedSeats.push(leftId1);
          }
        }
      }
    }
    if (isolatedSeats.length > 0 && action != null && seatObject != null) {
      this.singleSeatHandling(seatObject, seatObject.id, action);
    }
    return isolatedSeats;
  }

  // Check NotAvailable || Selected
  private check1(id) {
    if (id) {
      const element = this.dvmService.viewer.getNodeById(id);
      if (element) {
        return element.state === 'unavailable' || element.state === 'selected';
      }
    }
    return true;
  }
  // Check Available && Selected
  private check2(id) {
    if (id) {
      const element = this.dvmService.viewer.getNodeById(id);
      if (element) {
        return element.state === 'available';
      }
    }
    return false;
  }

  /*
  * CHECK IF BUFFER NEEDS TO BE RESET
  */
  public checkResetBuffer(areaid) {
    if (this.singleSeatsBuffer.length > 0 && this.singleSeatsAction === 'add') {
      // AREAID CHANGE CONDITION
      if (areaid !== this.singleSeatsBuffer[0].areaid) {
        this.singleSeatsBuffer.length = 0;
      }
    }
  }

  /*
  * RESET BUFFER
  */
  public resetBuffer() {
    this.singleSeatsBuffer.length = 0;
    this.singleSeatsAction = '';
  }

  /*
  * TO BE EXECUTED WHEN A SEAT IS UNSELECTED AND THE ISOLATED SEATS BUFFER IS NOT EMPTY
  */
  public singleSeatHandling(seatObject, id, action) {
    // IF CURRENT ACTION IS NOT 'REMOVE'
    if (this.singleSeatsAction !== action) {
      if (this.singleSeatsBuffer.length > 0) {
        // IF SEAT SELECTED IS ON THE BUFFER, WE REMOVE IT
        if (this.dvmService.viewer.getNodeById(id).tag && this.dvmService.viewer.getNodeById(id).tag === 'pending') {
          this.singleSeatsBuffer.length = 0;
        } else {
          // IF NOT, WE SEND WARNING TO THE USER
          this.errorCode.getErrorByCode(1006).subscribe(
            errorMessage => {
              this.modals.errorModal(errorMessage);
            }
          );
        }
        // PRINT BUFFER UPDATES ON THE MAP
        this.printBuffer();
        // status.seatmap.showLoader = false;
        return;
        // IF BUFFER IS EMPTY, WE SET THE NEW ACTION
      } else if (this.singleSeatsBuffer.length === 0) {
        this.singleSeatsAction = action;
      }
    }
    // CREATE THE SINGLE SEAT OBJECT FOR THE CURRENT ACTION
    const singleSeat = { id, seatObject };

    // IF THE ISOLATED SEATS BUFFER IS EMPTY OR CONTAINS 1 ITEM, WE ADD THE SEAT TO THE BUFFER
    if (this.singleSeatsBuffer.length <= 1) {
      this.singleSeatsBuffer.push(singleSeat);
    } else {
      // IF THE BUFFER ALREADY CONTAINS TWO SEATS, WARNING IS SENT TO THE USER
      this.errorCode.getErrorByCode(1006).subscribe(
        errorMessage => {
          this.modals.errorModal(errorMessage);
        }
      );
    }
    // IF THE BUFFER CONTAINS TWO SEATS, THEY ARE SENT TO BE RESOLVED
    if (this.singleSeatsBuffer.length >= 2) {
      if (this.singleSeatsAction === 'remove') {
        this.sendSingleSeatsRemoveBuffer();
      } else if (this.singleSeatsAction === 'add') {
        this.sendSingleSeatsAddBuffer();
      }
    }
    // 'PENDING' STATUS IS ADDED TO THE SEATS IN THE BUFFER
    // for (var i = 0; i < this.singleSeatsBuffer.length; ++i) {
    //   status.seatmap.moduleRef['addStatus'](this.singleSeatsBuffer[i]['mmcid'],"pending");
    // }
    this.printBuffer();
  }

  /*
  * SEND BUFFER TO BE RESOLVED WHEN 'REMOVE' ACTION IS ACTIVE
  */
  private sendSingleSeatsRemoveBuffer() {
    if (this.singleSeatsBuffer.length > 0) {
      const isolatedSeats = this.checkPendingIsolatedSeats();
      if (isolatedSeats.length > 0) {
        this.dvmService.viewer.select(this.singleSeatsBuffer[1].id);
        this.singleSeatsBuffer.length = 1;
        this.printBuffer();
        this.errorCode.getErrorByCode(1006).subscribe(
          errorMessage => {
            this.modals.errorModal(errorMessage);
          }
        );
      } else {
        for (const seat of this.singleSeatsBuffer) {
          delete this.flowControl.Basket[seat.id];
          this.pricing.restorePrice(seat.id);
          this.dvmService.viewer.unselect(seat.id);
          this.dvmService.viewer.setNodesTag(seat.id, null);
        }
        this.singleSeatsBuffer.length = 0;
      }
      // status.seatmap.showLoader = false;
    }
  }

  /*
  * SEND BUFFER TO BE RESOLVED WHEN 'ADD' ACTION IS ACTIVE
  */
  private sendSingleSeatsAddBuffer() {
    if (this.singleSeatsBuffer.length > 0) {
      this.dvmService.viewer.select(this.singleSeatsBuffer[1].id);
      const isolatedSeats = this.checkPendingIsolatedSeats();
      if (isolatedSeats.length > 0) {
        this.dvmService.viewer.unselect(this.singleSeatsBuffer[1].id);
        this.singleSeatsBuffer.length = 1;
        this.printBuffer();
        this.errorCode.getErrorByCode(1006).subscribe(
          errorMessage => {
            this.modals.errorModal(errorMessage);
          }
        );
      } else {
        if ((Object.keys(this.flowControl.Basket).length + 1) < (this.flowControl.relocationSeatsCount + this.flowControl.maxAddons)) {
          for (const seat of this.singleSeatsBuffer) {
            this.flowControl.Basket[seat.id] = seat.seatObject;
            this.dvmService.viewer.select(seat.id);
            this.dvmService.viewer.setNodesTag(seat.id, null);
          }
        } else {
          this.dvmService.viewer.unselect(this.singleSeatsBuffer);
          this.dvmService.viewer.setNodesTag(this.singleSeatsBuffer, null);
          this.errorCode.getErrorByCode(1007).subscribe(
            errorMessage => {
              this.modals.errorModal(errorMessage);
            }
          );
        }
        this.singleSeatsBuffer.length = 0;
      }
      // status.seatmap.showLoader = false;
    }
  }

  /*
  * PRINT BUFFER CHANGES IN THE SEATMAP
  */
  public printBuffer() {
    let action;
    let counteraction;
    if (this.singleSeatsAction === 'add') {
      action = 'select';
      counteraction = 'unselect';
    } else if (this.singleSeatsAction === 'remove') {
      action = 'unselect';
      counteraction = 'select';
    }
    const pendingElements = this.dvmService.viewer.getNodesByTag('seat', 'pending');
    if (pendingElements.length > 0 && pendingElements != null) {
      for (const seat of pendingElements) {
        this.dvmService.viewer[counteraction](seat.id);
        this.dvmService.viewer.setNodesTag(seat.id, null);
      }
    }
    if (this.singleSeatsBuffer.length > 0) {
      for (const seat of this.singleSeatsBuffer) {
        this.dvmService.viewer[action](seat.id);
        this.dvmService.viewer.setNodesTag(seat.id, 'pending');
      }
    }
  }
}
