import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeatSelectionComponent } from './seatselection.component';
import { SharedModule } from '../shared/shared.module';
import { DigitalvenueRoutingModule } from '../digitalvenue.routing.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DigitalvenueRoutingModule
  ],
  declarations: [SeatSelectionComponent, SeatSelectionComponent],
})
export class SeatSelectionModule { }
