import { Injectable } from '@angular/core';
import { User } from '../shared/models/user.model';
import { DjangoSessionAuthenticationService } from './django-session-authentication.service';
import { CustomerPlan, Transaction } from '../shared/models';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    user: User;
    user$;
    private urlSubject = new Subject<any>();
    private selectionAllowed = new Subject<any>();

    constructor(private auth: DjangoSessionAuthenticationService,
                private http: HttpClient) {

        this.user$ = this.auth.getUserLogged$();
        this.updateUser();
    }

    /**
     * Updates this.user with the new value of user
     */
    public updateUser(): void {
        // Subscribe to the user$
        this.user$.subscribe(user => {
            if (user) {
                // if user exist then updates with the user returned by observable
                this.user = user;
            }
        }, error => {
            console.log(error);
        });
    }

    /**
     * Post or Get request
     * @param endpoint url
     * @param body params
     * @returns Observable
     */
    private sendRequest(endpoint, body = null, responsetype = null): Observable<any> {
        if (!responsetype) {
            responsetype = "json";
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`
            }),
            responseType: responsetype
        };
        const finalUrl = `${endpoint}`;
        const observable = body ? this.http.post(finalUrl, body, httpOptions) : this.http.get(finalUrl, httpOptions);
        return observable.pipe();
    }

    /**
     * Delete request
     * @param endpoint url
     * @returns Observable
     */
    private deleteRequest(endpoint, body = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`
            }),
            body
        };
        const finalUrl = `${endpoint}`;
        const observable = this.http.delete(finalUrl, httpOptions);
        return observable.pipe();
    }

    /**
     * Put request
     * @param endpoint url
     * @returns Observable
     */
    private putRequest(endpoint, body = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`
            })
        };
        const finalUrl = `${endpoint}`;
        const observable = this.http.put(finalUrl, body, httpOptions);
        return observable.pipe();
    }


    /**
     * Checks if user exist and returns the CustomerPlan
     * @returns Array of CustomerPlan or empty array
     */
    getCustomerPlans(): Array<CustomerPlan> {
        if (this.user) {
            // if user exist returns the customerPlan
            return this.user.plans;
        } else {
            // if does not exist, returns empty array
            return [];
        }
    }

    /**
     * Returns the customerplan by id
     * @param planId to return the customerplan
     * @returns CustomerPlan in case of user exist and have plans, otherwise null
     */
    getCustomerPlanById(planId: number): CustomerPlan {
        // this.auth.getUserLogged$().subscribe(
        //     user => {
        //         console.log(user);
        // });
        if (this.user) {
            // if user exist and array plans is > than 0
            if (this.user.plans.length > 0) {
                // returns the customerPlan by id
                return this.user.plans.find(x => x.id === planId);
            }
        } else {
            // otherwise returns null
            return null;
        }
    }

    /**
     * Returns the customerplan transaction by id
     * @param planId to return the customerplan transaction
     * @returns Transaction in case of user exist and have plans, otherwise null
     */
    getTransactionByPlanId(planId: number): Transaction {
        if (this.user) {
            // if user exist and array plans is > than 0
            if (this.user.plans.length > 0) {
                // returns the transaction by planId
                let transaction = this.user.plans.find(x => x.id === planId).transaction;
                if (transaction.master === false) {
                    transaction = transaction.related;
                }
                return transaction;
            }
        } else {
            // otherwise returns null
            return null;
        }
    }

    /**
     * Sets this.user
     * @param user to set
     */
    setUser(user): void {
        this.user = user;
    }

    /**
     * Gets this.user
     * @returns User
     */
    getUser(): User {
        return this.user;
    }

    // Reset Password
    private _postResetPassword(email: string) {
        const body = {
        email
        }
        let endpoint = `/auth/password-reset/`;
        return this.sendRequest(endpoint, body, "text");
    }

    // Register Customer Finalize
    private _postResetPasswordFinalize(token: string, password: string) {
        const body = {
        token,
        password
        }
        let endpoint = `/auth/password-reset/finalize/`;
        return this.sendRequest(endpoint, body, "text");
    }

    /**
     * Observable that calls the function deleteRequest
     * @param transactionId to add on the endpoint
     */
    private _cancelTransaction(transactionId: number): Observable<any> {
        const endpoint = `/transaction/${transactionId}/`;
        return this.deleteRequest(endpoint);
    }

    /**
     * Calls the observable this._cancelTransaction and returns the data
     * @param transactionId to cancel the transaction
     * @returns data value
     */
    public cancelTransaction(transactionId: number) {
        return this._cancelTransaction(transactionId).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    /**
     * Observable that calls the function putRequest
     * @param transactionId to add on the endpoint
     */
    private _reserveTransaction(transactionId: number, body): Observable<any> {
        const endpoint = `/transaction/${transactionId}/`;
        return this.putRequest(endpoint, body);
      }

    /**
     * Calls the observable this._reserveTransaction and returns the data
     * @param transactionId to reserve the transaction
     * @returns data value
     */
    public reserveTransaction(transactionId: number, paymentOptions) {
        let body: any = {};
        // if reservation is true we don't need anything else
        // paymentOptions.reservation = false;
        if (paymentOptions.reservation === true) {
            body.reservation = true;
        } else {
        // set the needed options in the body
        // if we have any payment, full or payment plan with init payment set the payment required attributes
        if (paymentOptions.option === 'full' || paymentOptions.payment_plan_initial_payment) {
            if (paymentOptions.credit_to_apply > 0) {
                body.credit_to_apply = paymentOptions.credit_to_apply;
                // if we are only paying with credit, set credit to apply to total balance per safety reasons
                if (paymentOptions.credit_to_apply >= paymentOptions.total_balance) {
                    body.credit_to_apply = paymentOptions.total_balance;
                }
            }
            // if not all is covered with credit apply card or  digital wallet payment
            if (paymentOptions.credit_to_apply < paymentOptions.total_balance) {
                if (paymentOptions.card_wallet === 'card') {
                    // tslint:disable-next-line:max-line-length
                    body.payment_nonce = {value: window['payment_nonce']['nonce'], card_type: window['payment_nonce']['details']['cardType']};
                } else if (paymentOptions.card_wallet === 'wallet') {
                    body.digital_wallet = {id: paymentOptions.digital_wallet.id, card_type: paymentOptions.digital_wallet.description};
                }
            }
        }
        // if we are paying with plan, set his options, is not and else because if we have a initial payment then the plan options
        // will not be set
        if (paymentOptions.option === 'plan') {
            body.payment_plan = JSON.parse(JSON.stringify(paymentOptions.payment_plan));
            body.payment_plan.consume_credits = paymentOptions.consume_credits;
            // if we have not installments delete them or else assign digital wallet
            if (paymentOptions.no_installments) {
            delete body.payment_plan.installments;
            } else {
            body.digital_wallet = {id: paymentOptions.digital_wallet.id, card_type: paymentOptions.digital_wallet.description};
            }
            // if we have an initial payment, set the attribute duenow_installment_amount
            // this attribute is needed because if we delete the installments we don't know if we have an initial payment and how much is
            if (paymentOptions.payment_plan_initial_payment) {
            body.duenow_installment_amount = paymentOptions.total_balance;
            }
        }
        }

        return this._reserveTransaction(transactionId, body).pipe(
        map((data: any) => {
            return data;
        })
        );
    }

    // Change seats status from transaction
    // /tmp/seat_transaction/[transaction_id]/
    // body: {'seats':['S_204-1-1, S_Lexus Club]}
    private _editTransactionSeats(transactionId: number, seats: string[], action: 'remove' | 'add') {
        const endpoint =  `/seat_transaction/${transactionId}/`;
        const body = {
            seats
        };

        if (action === 'remove') {
            return this.deleteRequest(endpoint, body);
        } else if (action === 'add') {
            return this.putRequest(endpoint, body);
        }
    }

    public editTransactionSeats(transactionId: number, seats: string[], action: 'remove' | 'add'): Observable<any> {
        return this._editTransactionSeats(transactionId, seats, action).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public sendUrl(url: string): void {
        this.urlSubject.next(url);
    }

    public clearUrl(): void {
        this.urlSubject.next();
    }

    public getUrl(): Observable<any> {
        return this.urlSubject.asObservable();
    }

    public setSelectionAllowed(bol: boolean): void {
        this.selectionAllowed.next(bol);
    }

    public clearSelectionAllowed(): void {
        this.urlSubject.next();
    }

    public getSelectionAllowed(): Observable<any> {
        return this.selectionAllowed.asObservable();
    }

    // Post Customer Register
    public postResetPassword(email: string): Observable<any> {
        return this._postResetPassword(email).pipe(
        map((data: any) => {
            return data;
        })
        );
    }

    // Post Customer Register Finalize
    public postResetPasswordFinalize(token: string, password: string): Observable<any> {
        return this._postResetPasswordFinalize(token, password).pipe(
        map((data: any) => {
            return data;
        })
        );
    }

}
