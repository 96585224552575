import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainInterfaceComponent } from '../main-interface/main-interface.component';
import { SubInterfaceComponent } from '../sub-interface/sub-interface.component';
import { TicketComponent } from '../ticket/ticket.component';
import { TopbarComponent } from '../topbar/topbar.component';
import { BottomInterfaceComponent } from '../bottom-interface/bottom-interface.component';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SearchComponent } from '../search/search.component';
import { PriceScalesComponent } from '../price-scales/price-scales.component';
import { SharedModule as GeneralSharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    MainInterfaceComponent,
    SubInterfaceComponent,
    TicketComponent,
    TopbarComponent,
    BottomInterfaceComponent,
    SearchComponent,
    PriceScalesComponent
  ],
  exports: [
    MainInterfaceComponent,
    SubInterfaceComponent,
    TicketComponent,
    TopbarComponent,
    BottomInterfaceComponent,
    SearchComponent,
    PriceScalesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    GeneralSharedModule
  ],
  providers: [
  ]
})
export class SharedModule { }
