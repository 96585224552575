import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  isStorageAvailable: boolean;

  constructor() { }


  /**
   * Get all items from localStorage
   * @returns values, an array with localstorage key items
   */
  getAllItems(): Array<string> {
    const values = [];
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      values.push(localStorage.getItem(keys[i]));
    }
    return values;

  }

  /**
   * Gets item from localstorage
   * @param item to get the value
   */
  getItem(item: string): string {
    return localStorage.getItem(item);
  }

  /**
   * Sets an item on the localStorage
   * @param itemName key of item
   * @param item value of item
   */
  setItem(itemName: string, item: string): void {
    localStorage.setItem(itemName, item);
  }

  /**
   * Check if storage is available on the browser
   * @param type of storage
   */
  storageAvailable(type) {
    let storage;
    try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
  }
}
