import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ModalsService } from '../modals.service';
import { CustomerPlan } from '../../models';
import { Subject } from 'rxjs';
import { ThemeSelectorService } from 'src/app/services';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';

@Component({
  selector: 'app-plan-seat-element-modal',
  templateUrl: './plan-seat-element-modal.component.html',
  styleUrls: ['./plan-seat-element-modal.component.scss']
})
export class PlanSeatElementModalComponent implements OnInit {

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  customerPlan: CustomerPlan;
  accordionToggleSubject: Subject<number>;
  isDarkTheme: boolean;

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              public bsModalRef: BsModalRef,
              private modalService: ModalsService,
              private themeSelector: ThemeSelectorService,
              private bsModalService: BsModalService) { }

  ngOnInit() {
    if (this.bsModalRef) {
      this.bsModalService.onHidden.subscribe( $reason => {
        const reason = $reason;
        if (reason === 'backdrop-click') {
          this.accordionToggleSubject.next();
        }
      });
    }
  }

  /**
   * Display the pano view modal and send seatID and height as param
   * @param seatID to use on the 360 view
   */
  open360Modal(seatId: string) {
    this.modalService.panoViewModal(seatId);
  }


  /**
   * Hides the modal and next the subject to close the accordion
   */
  closeModal(): void {
    this.bsModalRef.hide();
    this.accordionToggleSubject.next();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

    isParking(section: string): boolean {
        let isparking = false;
        const parkingSectionList = ['PARKING', 'PARKRES', 'PREMIUM', 'STMRES', 'TRIPLECRWN'];
        for (const psection of parkingSectionList) {
            if (section === psection) {
                isparking = true;
            }
        }
        return isparking;
    }

}
