import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Pipe({
  name: 'sectionFormatter'
})
export class SectionFormatterPipe implements PipeTransform {

  constructor(public configService: ConfigurationService) {
  }

  transform(value: string): any {
    if (!value) {
      return value;
    }
    // royals asks to change (INT) per (GA), mmc has int in the id
    // for instance change 211(INT) per 211(GA)
    if (this.configService.client === 'kcroyals') {
      value = value.replace('(INT)', '(GA)');
    }
    return value;
  }

}
