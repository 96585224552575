import { Component, Inject, OnInit } from '@angular/core';
import { DjangoSessionAuthenticationService, ErrorJsonService } from '../services';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  user$;
  isFormInvalid = false;
  errorText: string;

  isProcessEnabled = true;
  processDisabledText;

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private auth: DjangoSessionAuthenticationService,
              private errorCodeService: ErrorJsonService,
              public configService: ConfigurationService,
              private router: Router) { }
  ngOnInit() {
    let dateEnd = null;
    if (this.app_config.endDate && new Date(this.app_config.endDate)) {
      dateEnd = new Date(this.app_config.endDate);
      this.isProcessEnabled = new Date() < dateEnd;
      if (this.app_config.text.processDisabledText) {
        this.processDisabledText = this.app_config.text.processDisabledText;
      }
    }
  }

  /**
   * Checks if the user exists. If not, throws an error to the view
   */
  login() {
    // subscribe to the login method on the auth service
    this.auth.login({ username: this.username, password: this.password }).subscribe(
      success => {
        this.router.navigate(['/home']);
      },
      error => {
        // if no user exist, shows an error 1000 to the view
        this.isFormInvalid = true;
        this.errorCodeService.getErrorByCode(1000).subscribe(
          errorText => {
            this.errorText = errorText;
          }
        );
      }
    );
  }

}
