import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowControlService } from '../../../digitalvenue/services/flow-control.service';
import { ThemeSelectorService, UserService } from '../../../services';

@Component({
  selector: 'app-twins-warm-welcome-modal',
  templateUrl: './twins-warm-welcome-modal.component.html',
  styleUrls: ['./twins-warm-welcome-modal.component.scss']
})
export class TwinsWarmWelcomeModalComponent implements OnInit {

  isDarkTheme: boolean;
  customerPlan;

  constructor(public bsModalRef: BsModalRef,
              public flowControl: FlowControlService,
              private userService: UserService,
              private themeSelector: ThemeSelectorService) {
  }

  ngOnInit() {

  }


  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
