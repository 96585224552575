import { Component, Inject, OnInit } from '@angular/core';
import { ThemeSelectorService, LocalStorageService, UserService } from './services';
import { Router, NavigationEnd } from '@angular/router';
import { User } from './shared/models';
import { APP_CONFIG } from './configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from './configuration/ticket-management-portal-configuration.model';
import { ConfigurationService } from './services/configuration.service';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: User;
  title = 'staplescenter-ticket-management-portal';

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private themeSelector: ThemeSelectorService,
              private userService: UserService,
              private storageService: LocalStorageService,
              public configService: ConfigurationService,
              private router: Router) {
                this.router.events.subscribe(event => {
                  if (event instanceof NavigationEnd) {
                      gtag('config', 'UA-74364748-15',
                            {
                              page_path: event.urlAfterRedirects
                            }
                           );
                   }
                });
              }

  ngOnInit() {

    document.documentElement.style.setProperty('--main-color', this.app_config.colors.main);
    document.documentElement.style.setProperty('--accent-color', this.app_config.colors.accent);
    document.documentElement.style.setProperty('--glow-color', this.app_config.colors.glow);

    if (this.storageService.storageAvailable('localStorage')) {
      this.storageService.isStorageAvailable = true;
    }
    this.userService.user$.subscribe(
      user => {
        this.user = user;
      }
    );
  }

  isAdmin() {
    if (this.user) {
      return this.user.hasOwnProperty('admin_takeover');
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }
}

