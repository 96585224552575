import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview.component';
import { DigitalvenueRoutingModule } from '../digitalvenue.routing.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DigitalvenueRoutingModule
  ],
  declarations: [PreviewComponent],
})
export class PreviewModule { }
