import { Component, OnInit, Inject } from '@angular/core';
import { ThemeSelectorService, DjangoSessionAuthenticationService, UserService } from '../services';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';
import { Router } from '@angular/router';
import { MessagingService } from '../shared/modals/messaging.service';
import { FlowControlService } from '../digitalvenue/services/flow-control.service';
import { ModalsService } from '../shared/modals/modals.service';
import { DigitalvenueConfiguration } from '../digitalvenue/digitalvenue-configuration.model';
import { DV_CONFIG } from '../digitalvenue/digitalvenue.configuration';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user$;
  showButton = true;
  isDarkMode: boolean;
  showHomebutton = false;
  isSelectionAllowed = false;
  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              @Inject(DV_CONFIG) public dvConfig: DigitalvenueConfiguration,
              private themeService: ThemeSelectorService,
              private auth: DjangoSessionAuthenticationService,
              public configService: ConfigurationService,
              private router: Router,
              private userService: UserService,
              private messageService: MessagingService,
              private flowControl: FlowControlService,
              private modalService: ModalsService) { }

  ngOnInit() {
    this.isDarkMode = this.themeService.getIsDarkTheme();
    this.user$ = this.auth.getUserLogged$();
    this.user$.subscribe( user => {
      (user) ? this.showButton = true : this.showButton = false;
    });
    this.userService.getUrl().subscribe(
      response => {
        if (response) {
          this.showHomebutton = true;
        } else {
          this.showHomebutton = false;
        }
      }
    );
    this.userService.getSelectionAllowed().subscribe(
      response => {
        if (response) {
          this.isSelectionAllowed = true;
        } else {
          this.isSelectionAllowed = false;
        }
      }
    );
  }

  openInfoModal() {
    this.modalService.infoModal();
  }

  toggleTheme(event: any): void {
    this.themeService.setDarkTheme(this.isDarkMode);
  }

  logout(): void {
    this.auth.logout().subscribe(
      success => {
        this.router.navigate(['/login']);
      },
      error => {
        console.error(error);
      }
    );
  }

  goBack(): void {

    const title = 'Return to the previous step?';
    const message = 'You will lose your current selection. Are you sure you want to go back to the previous step?';
    const acceptBtnName = 'Confirm';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => {
        if (this.isSelectionAllowed) {
            this.router.navigate(['/digitalvenue/preview', this.flowControl.currentPlan]);
        } else {
          this.router.navigate(['/home']);
        }
    }, closeBtnName);
  }

  nationalsTermsAndConditions(): void {
      this.modalService.nationalsTC();
  }

  public isMobile(): boolean {
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
    } else {
        return false;
    }
}

}
