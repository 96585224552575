import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { UserService } from 'src/app/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  username;
  password;
  password2;
  backgroundUrl;

  token;

  requestSent = false;

  constructor(@Inject(APP_CONFIG) public appConfig: TicketManagementPortalConfiguration,
              private userService: UserService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params) {
      if (this.activatedRoute.snapshot.params.t) {
        this.token = this.activatedRoute.snapshot.params.t;
      }
    }
  }

  sendEmail() {
    if (this.username) {
      this.userService.postResetPassword(this.username).subscribe(
        response => {
          this.requestSent = true;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  register() {
    if (this.token && this.password && this.password2 && this.password === this.password2) {
      this.userService.postResetPasswordFinalize(this.token, this.password).subscribe(
        response => {
          this.requestSent = true;
        },
        error => {
          console.error(error);
          // this.dataService.isLoaderActive = false;
        }
      );
    }
  }


}
