// import { SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';
import { environment } from '../../environments/environment';
import { DigitalvenueConfiguration } from './digitalvenue-configuration.model';
import { InjectionToken } from '@angular/core';
import { DVMConfiguration } from './dvmconfiguration.model';

/*
*
* Application Configuration
*
*/
export const DV_CONFIG = new InjectionToken<DigitalvenueConfiguration>('Digitalvenue Configuration');
export const DigitalVenueConfig: DigitalvenueConfiguration = {
  // Select the type of application to load: Relocation or Season Tickets
  applicationType: 'Relocation',
  // Include the areas to be treated as GA, and the areas to be locked in the map
  lockedAreas: [],
  // Include areas search bar
  includeSearch: true,
  // Add-ons configuration (only with applicationType: Relocation)
  addonsConfiguration: {
    isAddonsAvailable: true,
    addonsMaxSelection: 2
  },
  // Parking IDs and configuration
  parkingConfiguration: {
    isParkingAvailable: false,
    // Maximum slots per user
    parkingMaxSelection: null
  },
  // Data to be shown in the popovers (it has to be previously imported)
  popoversConfiguration: {
    includeDescription: true,
    includeSeatsCount: true,
    includePricing: true
  },
  // Maximum seats per user
  seatsMaxSelection: 60,
  // Data Providers available: SeatGeek, MMC
  dataProvider: 'MMC',
  // Use Pending Buffer for isolated seats error
  preventIsolatedSeats: true,
  // Load areamap or blockmap
  mapType: 'blockmap',
  // Interface colors
  colors: {
    mapElement: {
      available: '#1F8EFA',
      selected: '#eaeaea',
      pending: 'orange'
    },
    buttons: '#d81c2b',
    buttonsHover: '#007bff',
    popoverText: '#98A7B9',
    topbar: '#141414',
    bottomInterface: '#141414',
    subInterface: {
      header: '#141414',
      listTitle: '#b8b6b7'
    },
    tickets: {
      text: '#252525',
      actions: '#252525',
      actionsHover: '#398be2'
    }
  }
};

export const DVM_CONFIG = new InjectionToken<DVMConfiguration>('DVM Configuration');
export const DVMConfig: DVMConfiguration = {
    venue_id: 'nam-us-00096-chicagocubs',
    map_id: 'blockmap',
    container: 'viewer-container',
    // version: 'development'
};
