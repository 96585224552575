import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowControlService } from '../../../digitalvenue/services/flow-control.service';
import { ThemeSelectorService, UserService } from '../../../services';
import { Router } from '@angular/router';
import { APIConnectionService } from '../../../digitalvenue/services/api-connection.service';
import { CustomerPlan, Plan } from '../../models';

@Component({
  selector: 'app-twins-flex-addons-modal',
  templateUrl: './twins-flex-addons-modal.component.html',
  styleUrls: ['./twins-flex-addons-modal.component.scss']
})
export class TwinsFlexAddonsModalComponent implements OnInit {
  isDarkTheme: boolean;
  customerPlan;

  constructor(public bsModalRef: BsModalRef,
              public flowControl: FlowControlService,
              private router: Router,
              private userService: UserService,
              private apiConnectionService: APIConnectionService,
              private themeSelector: ThemeSelectorService) {
  }

  @Input() plan: Plan;
  plans;
  selectedPlan: Plan | null = null;
  noAddonsAllowed: boolean;

  ngOnInit() {
    this.apiConnectionService.flexAddonsAvailability(this.plan.id).subscribe(data => {
      this.plans = data;
      if (this.plans.lenght === 0) {
        this.noAddonsAllowed = true;
      }
    });
  }


  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  /**
   * Ya que hemos pasado a funcionar con un select html, el value de las options no puede ser 
   * el objeto a pelo, asi que creo este metodo para invocarlo en el front y converting a string 
   * el valor de las opciones. 
   * */ 
  stringifyData(data: Object){
    return JSON.stringify(data)
  }

  preSelectPlan(plan: string){
    const planData: Plan = JSON.parse(plan);
    this.selectedPlan = planData;
  }

  onSelectPlan(plan: Plan) {
    const customerPlan: CustomerPlan   = this.userService.getCustomerPlanById(this.flowControl.currentPlan);

    this.flowControl.justAddonsAllowed = true; // hay que poner esto por el guard

    for (const seat of customerPlan.customer_plan_seats) {
      this.flowControl.seatsToKeep.push(seat.id);
    }
    
    this.flowControl.addonFlexPlax = plan;
    this.flowControl.availabilty.next([]);
    this.router.navigate(['/digitalvenue/seatselection',
      this.flowControl.currentPlan, plan.id], { state: { data: [] } });
    this.decline();
  }

}
