import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { APIConnectionService } from 'src/app/digitalvenue/services/api-connection.service';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { DjangoSessionAuthenticationService, ErrorJsonService, ThemeSelectorService, UserService } from 'src/app/services';
import { CustomerPlan } from '../../models';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ModalsService } from '../modals.service';

@Component({
  selector: 'app-checkout-modal',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss']
})
export class CheckoutModalComponent implements OnInit {

  
  title: string;
  message: string;
  isDarkTheme: boolean;
  customerPlan: CustomerPlan;

  constructor(public bsModalRef: BsModalRef,
              private flowControl: FlowControlService,
              private authService: DjangoSessionAuthenticationService,
              private connectionService: APIConnectionService,
              private router: Router,
              private modalService: ModalsService,
              private themeSelector: ThemeSelectorService) {
    this.title = 'Continue to checkout?';
    this.message = `By clicking confirm, your new seats will be placed on hold.
    Do you want to confirm your new seat selection?`;
  }

  ngOnInit() {
  }

  /**
   * Hides the modal, creates a hold transaction and updates the userData
   */
  confirm(): void {
    // creates an array with the selected seats and seats to keep
    let basket = Object.keys(this.flowControl.Basket);
    // for (let i = 0; i < basket.length; i++) {
    //   if (basket[i].split('-')[0] === 'S_Bleacher') {
    //     basket[i] = basket[i].split('-')[0];
    //   }
    // }
    const transactionSeats = basket.concat(this.flowControl.seatsToKeep);

    // creates a hold transaction to the current plan
    this.createHoldTransaction(transactionSeats);
    this.bsModalRef.hide();
  }

  createHoldTransaction(transactionSeats): void {
    this.connectionService.createHoldTransaction(this.flowControl.currentPlan, transactionSeats, this.flowControl.upgradePlan).subscribe(
      success => {
        this.authService.updateUserData().subscribe(
          response => {
            this.router.navigate(['/checkout', this.flowControl.currentPlan]);
          },
          error => {
            console.error(error);
            this.errorModal(error.error.message);
            // If error, shows a modal with the error code
            // this.errorCodeService.getErrorByCode(1004).subscribe(
            //   errorText => {
            //     // displays an error modal
            //     this.errorModal(errorText);
            //   }
            // );
          }
        );
      },
      error => {
        const errorMessage = error.error;
        switch (errorMessage.code) {
          case 'TCR03':
            this.errorModal(errorMessage.message);
            break;
          case 'TCRRS03':
            // this.isolatedSeatsApiService.setPendingSeats(errorMessage.seats);
            // this.isolatedSeatsApiService.printIsolatedSeats(errorMessage.seats);
            for (let i = 0; i < errorMessage.seats.length; i++) {
              errorMessage.seats[i] = errorMessage.seats[i].split('S_')[1];
            }
            this.errorModal(`The selected seats are leaving isolated seats: ${errorMessage.seats}`);
            break;
          default:
            console.error(error);
            this.errorModal(error.error.message);
            // If error, shows a modal with the error code
            // this.errorCodeService.getErrorByCode(1005).subscribe(
            //   errorText => {
            //     // displays an error modal
            //     this.errorModal(errorText);
            //   }
            // );
            break;
        }
      });
  }
  allExtrasSlots(allExtrasSlots: any) {
    throw new Error('Method not implemented.');
  }

  public errorModal(errorMessage): void  {
    const initialState = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      message: errorMessage
    };
    this.bsModalRef = this.modalService.show(ErrorModalComponent, { class: 'modal-dialog-centered', initialState });
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
    // make cancel
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
