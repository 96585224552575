import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG, DV_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { FlowControlService } from '../services/flow-control.service';
import { Seat } from 'src/app/shared/models';
import { ErrorJsonService } from 'src/app/services';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { PricingService } from '../services/pricing.service';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { APIConnectionService } from '../services/api-connection.service';
import { GroupConstraintService } from '../services/group-constraint.service'
import { ConfigurationService } from '../../services/configuration.service'

@Injectable()
export class ClickSeatselectionHandler extends GeneralHandler {

    generalAdmissionAreas = {};

    constructor(protected dvmService: DVMService,
                private pricing: PricingService,
                private connection: APIConnectionService,
                private errorCode: ErrorJsonService,
                private modals: ModalsService,
                private isolatedSeatsService: IsolatedSeatsService,
                private groupConstraintService: GroupConstraintService,
                private flowControl: FlowControlService,
                private configService: ConfigurationService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                @Inject(APP_CONFIG) private appConfig: TicketManagementPortalConfiguration,
                @Inject(DV_CONFIG) private digitalvenueConfig: DigitalvenueConfiguration) {
        super('click', dvmService);
        this.appConfig.generalAdmissionAreas.forEach(element => {
          this.generalAdmissionAreas[element] = true;
      });
    }

    sectionClickHandler(node, viewer) {
         if (node.state === 'available') {
          if (this.generalAdmissionAreas[node.id]) {
            if (Object.keys(this.flowControl.Basket).length < (this.flowControl.relocationSeatsCount + this.flowControl.maxAddons)) {
              this.flowControl.currentGeneralAdmissionArea = node.id;
              // if general admission we also get section availability and save in flowcontrol for use in general-admission-modal.component
              this.flowControl.showLoader(true);
              this.connection.getPlanSectionAvailability(this.flowControl.currentPlan,
              node.id, this.flowControl.upgradePlan).subscribe(
               planSectionAvailability => {
                 // tslint:disable-next-line: forin
                 for (const seatId in planSectionAvailability) {
                   this.pricing.seatpricing[seatId] = planSectionAvailability[seatId].price_type.prices[0].price.toFixed(2);
                 }
                 this.flowControl.currentGeneralAdmissionAvailability = planSectionAvailability;
                 this.flowControl.showLoader(false);
                 this.modals.generalAdmissionModal();
               },
               error => {
                 console.error(error);
                 if (error.error && error.error.code && !error.error.code.startsWith('pv')) {
                   this.modals.errorModal(error.error.message);
                 } else {
                  //  this.errorCodeService.getErrorByCode(1008).subscribe(
                  //    errorText => {
                  //      // displays an error modal
                  //      this.modals.errorModal(errorText);
                  //    }
                  //  );
                  this.modals.errorModal(error);
                 }
                //  status.seatmap.showLoader = false;
               }
             );
           } else {
               this.modals.errorModal('You have reached the maximum number of seats allowed for your plan.');
           }
          } else {
            const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
            loadOptions.map_id = node.id;
            loadOptions.venue_id = this.dvmService.viewer.getVenueId();
            this.dvmService.loadMap(loadOptions);
          }
        }
    }

    seatClickHandler(node, viewer) {
        if (node.state === 'available' || node.state === 'selected') {
            const id = node.id;
            const split = id.split('_')[1].split('-');
            const section = split[0];
            // tslint:disable-next-line: variable-name
            const seat_row = split[1];
            const seat = split[2];
            const price_type = this.pricing.seatpricing[id].price_type;
            const seatObject: Seat = {
              id, section, seat_row, seat, price: null, price_type
            };

            // IF NODE IS NOT SELECTED
            if (node.state !== 'selected') {
              // Setting the price
              const price = this.pricing.setPricing(id);
              seatObject.price = price;
              // ISOLATED SEATS CHECK
              const action = 'add';
              if (this.isolatedSeatsService.singleSeatsBuffer.length > 0 && this.digitalvenueConfig.preventIsolatedSeats) {
                this.isolatedSeatsService.singleSeatHandling(seatObject, id, action);
              } else if (this.flowControl.Basket[id] == null) {
                // If Relocated Seats are not completed
                if (Object.keys(this.flowControl.Basket).length < (this.flowControl.relocationSeatsCount + this.flowControl.maxAddons)) {
                  // ISOLATED SEATS ALROGITHM
                  let isolatedSeats = [];
                  if (this.digitalvenueConfig.preventIsolatedSeats) {
                    isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats([node], seatObject, action);
                  }
                  if (isolatedSeats.length === 0) {
                    viewer.select(node);
                    this.flowControl.Basket[id] = seatObject;
                    if(this.configService.client === 'clevelandguardians') {
                        viewer.setNodesTag(node.id, 'selected')
                        const seatGroupsToPreserve = 4
                        this.groupConstraintService.addSeatsToCache([node], seatGroupsToPreserve)
                    }
                  } else {
                    this.errorCode.getErrorByCode(1006).subscribe(
                      errorMessage => {
                        this.modals.errorModal(errorMessage);
                      }
                    );
                  }
                } else {
                  this.errorCode.getErrorByCode(1007).subscribe(
                    errorMessage => {
                      this.modals.errorModal(errorMessage);
                    }
                  );
                }
              }
            // IF NODE IS SELECTED
            } else {
              // ISOLATED SEATS CHECK
              const action = 'remove';
              if (this.isolatedSeatsService.singleSeatsBuffer.length > 0 && this.digitalvenueConfig.preventIsolatedSeats) {
                this.isolatedSeatsService.singleSeatHandling(seatObject, id, action);
              } else if (this.flowControl.Basket[id] != null) {
                // ISOLATED SEATS ALROGITHM
                viewer.unselect(node);
                const selectedElements = viewer.getNodesByState('seat', 'selected');
                let isolatedSeats = [];
                if (this.digitalvenueConfig.preventIsolatedSeats) {
                  isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats(selectedElements, seatObject, action);
                }
                if (isolatedSeats.length === 0) {
                  delete this.flowControl.Basket[id];
                  this.pricing.restorePrice(id);
                } else {
                  this.errorCode.getErrorByCode(1006).subscribe(
                    errorMessage => {
                      this.modals.errorModal(errorMessage);
                    }
                  );
                }
              }
            }
        }
    }

    protected handle(obj) {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');


        // Handle
        const viewer = obj.instance;
        let nodes = obj.nodes;

        if (this.appConfig.rules.selectSeatsInGroupsOf && this.appConfig.rules.selectSeatsInGroupsOf > 1) {
          const neighbors = viewer.getNeighbors(obj.nodes[0], this.appConfig.rules.selectSeatsInGroupsOf, true);

          if (neighbors && neighbors.length > 1) {
            nodes = neighbors;
          }
        }

        if (nodes.length) {
          nodes.forEach(node => {
            if (node.type === 'section') {
              // if (this.flowControl.suitesHash[nodes[0].id]) {
              //     this.dvmService.load3DView(nodes[0].id);
              // } else {
              //     this.sectionClickHandler(nodes[0], viewer);
              // }
              this.sectionClickHandler(node, viewer);
            } else if (nodes[0].type === 'seat') {
                this.seatClickHandler(node, viewer);
            }
          });
        }
    }
}
