import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DjangoSessionAuthenticationService } from 'src/app/services/django-session-authentication.service';
import { User } from '../shared/models/user.model';
import { ThemeSelectorService } from '../services/theme-selector.service';
import { LocalStorageService } from '../services/local-storage.service';
import { CustomerPlan } from '../shared/models';
import { Router } from '@angular/router';
import { UserService, ErrorJsonService } from '../services';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';
import { ConfigurationService } from '../services/configuration.service';
import { ModalsService } from '../shared/modals/modals.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  errorText: string;
  public userData;
  isDarkTheme: boolean;

  accordionHash = {};
  accordionToggleSubject = new Subject<number>();
  accordionHashSubject = new Subject();
  user$;
  user: User;
  sortedCustomerPlan: {
      plansFilled: Array<CustomerPlan>,
      plansNoFilled: Array<CustomerPlan>
  };
  parkingHash = {};

  totalParking: number;
  lexusHash = {};
  totalLexus: number;
  get getParkingHash() {
    return this.parkingHash;
  }
  get getLexusHash() {
    return this.lexusHash;
  }

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private auth: DjangoSessionAuthenticationService,
              public configService: ConfigurationService,
              private themeSelector: ThemeSelectorService,
              private storageService: LocalStorageService,
              private router: Router,
              private userService: UserService,
              private errorCodeService: ErrorJsonService,
              private modals: ModalsService) {
                this.lexusHash = {};
                this.parkingHash = {};

    }

  /**
   * Hides all accordions setting the accordionHash to false except the one clicked by the user (param)
   * and toggles the user selection.
   * @param id of the accordion
   */
  onToggleAccordion(id: number) {
    // loops the accordion checking if the key is equal to the id
    for (const [key, value] of Object.entries(this.accordionHash)) {
      if (parseInt(key, 10) === id) {
        continue;
      }
      this.accordionHash[key] = false;
    }
    this.accordionHash[id] = !this.accordionHash[id];
    this.accordionHashSubject.next(this.accordionHash);
  }


  ngOnInit() {
    if (this.storageService.isStorageAvailable) {
      if (!this.storageService.getItem('guideReaded')) {
        // this.modalService.infoModal();
      }
    }
    let first = true;
    this.user$ = this.auth.getUserLogged$();
    // Checks if the user is logged, if not returns to login otherwise sets the user to the service
    this.user$.subscribe(user => {
      if (!user) {
        this.router.navigate(['/login']);
      } else {
        if (!this.userService.getUser()) {
          // sets the user to the user service
          this.userService.setUser(user);
        }
        this.user = this.userService.getUser();
        this.rearrangeArray(this.user);
        this.setExtraHash(this.user.plans);
        console.log(this.user);
        if (this.user.plans.length === 0) {
          // if user have no plans actives then throws an error code 1001
          this.errorCodeService.getErrorByCode(1001).subscribe(
            errorText => {
              this.errorText = errorText;
            }
          );
        }
        if(this.configService.client === 'minnesotatwins'
          && this.user.plans.length === 1 && this.user.plans[0].customer_plan_seats.length === 0 && first) {
          this.modals.twinsWarmWelcome();
        }
      }
      first = false;
    });

    // subject subscribed that calls the function onToggleAccordion when nexts
    this.accordionToggleSubject.subscribe(
      accordionId => {
        this.onToggleAccordion(accordionId);
      }
    );
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  private rearrangeArray(user: User): void {
      const plansFilled = user.plans.filter(
          (x: CustomerPlan) => {
              return x.customer_plan_seats.length > 0;
          }
      );
      plansFilled.sort(
          (a: CustomerPlan, b: CustomerPlan) => {
              return a.plan.id >= b.plan.id ? 1 : -1;
          }
      );
      const plansNoFilled = user.plans.filter(
          (x: CustomerPlan) => {
              return x.customer_plan_seats.length === 0;
          }
      );
      plansNoFilled.sort(
          (a: CustomerPlan, b: CustomerPlan) => {
              return a.plan.id >= b.plan.id ? 1 : -1;
          }
      );

      this.sortedCustomerPlan = {plansNoFilled, plansFilled};
    // const compare = (a, b) => {
    //   if (a.plan.tier >= b.plan.tier) {
    //     return 1;
    //   } else {
    //     return -1;
    //   }
    // };
    // this.sortedCustomerPlan = user.plans.sort(compare);
  }

  /**
   * Sets the parkingHash and lexusHash from customerplan and customerplan transsaction
   * @param customerPlans to get parking and lexus
   */
  private setExtraHash(customerPlans: Array<CustomerPlan>): void {

    for (const cPlan of customerPlans) {
      if (cPlan.transaction && cPlan.transaction.status) {
        if (cPlan.transaction.status === 'PR' || cPlan.transaction.status === 'OK') {
          for (const seatTransaction of cPlan.transaction.seat_transactions) {
            const seat = seatTransaction.seat;
            const sectionName = seatTransaction.seat.split('_')[1].split('-')[0];
            if (sectionName === 'Lexus Club') {
              this.lexusHash[seatTransaction.seat] = seatTransaction;
            }
            if (sectionName === 'Lot C' || sectionName === 'Lot W' || sectionName === 'Lot 1' || sectionName === 'Lot E') {
              // this.parkingHash[sectionName][seat] = seatTransaction;
              this.parkingHash[seatTransaction.seat] = seatTransaction;
            }
          }
        }
      }
      for (const seat of cPlan.customer_plan_seats) {
        if (seat.section === 'Lot C' || seat.section === 'Lot 1' || seat.section === 'Lot W' || seat.section === 'Lot E') {
          // this.parkingHash[seat.section][seat.id] = seat;
          this.parkingHash[seat.id] = seat;
        }
        if (seat.section === 'Lexus Club') {
          this.lexusHash[seat.id] = seat;
        }
      }
    }
    this.totalLexus = Object.keys(this.lexusHash).length;
    this.totalParking = Object.keys(this.parkingHash).length;
  }

  customerHasPlans(): boolean {
      return this.user.plans[0].customer_plan_seats.length === 0;
  }
}
