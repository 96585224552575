import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerPlan, SeatTransaction, Seat, User } from 'src/app/shared/models';
import { UserService, ThemeSelectorService } from 'src/app/services';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  planId: number;
  customerPlan: CustomerPlan;
  isDarkTheme: boolean;
  user: User;
  isFlexMode: boolean;

  keptSeats = [];
  selectedSeats = [];
  discardedSeats = [];

  
  get quantity():string {
    if(this.customerPlan && this.isFlexMode && this.customerPlan.transaction.seat_transactions[0].action === 'add' ){     
      return this.customerPlan.transaction.seat_transactions[0].seat.split('-')[2]
    }else if(this.customerPlan.transaction.seat_transactions[0].action === 'remove' ||
             this.customerPlan.transaction.seat_transactions[0].action === 'keep' ){
      return '1'
    }else{
      return '1'
    }
  }

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private router: Router,
              private userService: UserService,
              private themeSelector: ThemeSelectorService,
              private messageService: MessagingService,
              private routeParam: ActivatedRoute,
              private flowControl: FlowControlService,
              private modals: ModalsService) {

  }

  ngOnInit() {
    this.planId = parseInt(this.routeParam.snapshot.paramMap.get('planId'), 10);
    // gets the user data
    this.user = this.userService.getUser();
    // gets the customer plan by id from the service
    this.customerPlan = this.userService.getCustomerPlanById(this.planId);

    //Determina si es Flex
    this.isFlexMode = this.flowControl.flexMode;

    if (this.customerPlan && this.customerPlan.transaction != null) {
      if (this.customerPlan.transaction.status === 'PH') {
        // if transaction status it's 'PH' means that is pending hold and has to be redirected to the checkout
        this.router.navigate(['/checkout', this.planId]);
      } else if (this.customerPlan.transaction.status === 'CA') {
        this.messageService.error('Transaction cancelled', 'The transaction has been cancelled. Please, contact your sales representative for support.');
        this.flowControl.selectedSection = null;
        this.router.navigate(['/home']);
      } else {
        // if transaction it's diferrent than 'PH' then filter the seats
        this.filterPlanSeats(this.customerPlan);
        this.modals.congratulationsModal();
      }
    } else {
      // if transaction it's null redirects to the landing page
      this.flowControl.selectedSection = null;
      this.router.navigate(['/home']);
    }
  }

  formatPrice(price: number) {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }

  absolute(num: number) {
    return Math.abs(num);
  }

  printPage() {
    window.print();
    return false;
  }

  /**
   * Loops the array selectedSeats and sums the price of all seats
   * @returns count, the selected total price
   */
  get totalPriceSelected(): number {
    let count = 0;
    for (const seat of this.selectedSeats) {
      count += seat.price;
    }
    for (const seat of this.keptSeats) {
      count += seat.price;
    }
    // return Number.parseFloat(count + '').toFixed(2);
    return count;
  }

  /**
   * Loops the array discardedSeats and sums the price of all seats
   * @returns count, the discarded total price
   */
  get totalPriceDiscarded(): number {
    let count = 0;
    for (const seat of this.discardedSeats) {
      count += seat.price;
    }
    for (const seat of this.keptSeats) {
      count += seat.price;
    }
    // return Number.parseFloat(count + '').toFixed(2);
    return count;
  }

  /**
   * Substract the total price of discarded and selected seats,
   * @returns object with the absolute price and a boolean
   */
  get totalPrice(): { 'isRefund': boolean, 'price': number } {
    const priceSelected = this.totalPriceSelected;
    const price = priceSelected;
    let isRefund;
    // checks if it's to pay or refund
    // (price >= 1) ? isRefund = true : isRefund = false;
    isRefund = false;
    return { 'isRefund': isRefund, 'price': price };
  }

  /**
   * Displays the SummaryModalComponent modal
   */
  openCheckoutModal(): void {
    const title = 'Process completed';
    const message = `Thank you for your participation in the online seat selection!`;
    const acceptBtnName = 'Continue';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => { this.goHome(); }, closeBtnName);
  }

  private goHome(): void {
    this.flowControl.selectedSection = null;
    this.router.navigate(['/home']);
  }

  /**
   * Filter the transaction and put the seats into their respective array
   * @param customerPlan to loop the transaction seats
   */
  filterPlanSeats(customerPlan: CustomerPlan): void {
    this.keptSeats = [];
    this.selectedSeats = [];
    this.discardedSeats = [];
    const pushSeats = (transactionSeatsArray) => {
      transactionSeatsArray.forEach(element => {
        if (element.action === 'keep') {
          // if action is 'keep' means that it has to be as discarded and selected
          this.keptSeats.push(this.transactionSeat2Seat(element, this.formatPrice(element.price)));
        }
        // if action is 'keep' means that it has to be as discarded
        if (element.action === 'remove') {
          this.discardedSeats.push(this.transactionSeat2Seat(element, this.formatPrice(element.price)));
        }
        // if action is 'keep' means that it has to be as  selected
        if (element.action === 'add') {
          this.selectedSeats.push(this.transactionSeat2Seat(element, this.formatPrice(element.price)));
        }
      });
    };
    pushSeats(customerPlan.transaction.seat_transactions);
    if (customerPlan.transaction.related) {
      pushSeats(customerPlan.transaction.related.seat_transactions);
    }
    this.keptSeats.sort();
  }

  /**
   * Parses the transaction seat into seat object
   * @param transactionSeat to parse
   * @returns Seat object
   */
  transactionSeat2Seat(transactionSeat: SeatTransaction, priceString = null) {
    // splits the transaction seat
    const seat = transactionSeat.seat.split('_')[1].split('-');
    return { id: transactionSeat.seat, seat: seat[2], seat_row: seat[1], section: seat[0], price: transactionSeat.price, priceString };
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
